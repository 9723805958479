export class User {
  constructor(public PkUser:number,
              public FkType:number,
              public Name:string,
              public LastName:string,
              public email:string,
              public created_at:string,
              public password:string,
              public isAdmin:boolean = false
             ){}
}

export class Language{
  constructor(
      public PkLanguage:number,
      public Name:string,
      public ShortName:string
  ){}
}

export class Proffesional{
  constructor(public PkProffesional:number,
              public FkUser:number,
              public FirstName:string,
              public Middle:string,
              public LastName:string,
              public specialty:Specialty[],
              public language:object[],
              public SpecialtyName:string,
              public Experience:number,
              public HourlyRate:number,
              public Qualifications:string,
              public Others:string,
              public Address:string,
              public Apartment:string,
              public City:string,
              public State:string, 
              public Zip:string,
              public Mobile:string,
              public Date:string,
              public Image:string,
              public Pdf:string,
              public Latitude:number,
              public Longitude:number,
              public Days:string = '',
              public Position:string = 'b',
              public user:User = null,
              public files:string[] = null,
              public FkInstitution:number = 0,
              public FullAddress:string = ''
              ){}
  static getInstance():Proffesional{
    return new Proffesional(0,0,'','','',[],[],'',0,0,'','','','','','','','','','','',0,0);
  }
}

export class Office{
  constructor(public PkOffice:number,
              public FkUser:number,
              public Name:string,
              public Contact:string,
              public Address:string,
              public City:string,
              public State:string, 
              public Zip:string,
              public Years:number,
              public Services:string,
              public Mobile:string,
              public Telephone:string,
              public Email:string,
              public Date:string,
              public Image:string,
              public Latitude:number,
              public Longitude:number,
              public user:User = null
              ){}

  static getInstance():Office{
    return new Office(0,0,'','','','','','',0,'','','','','','',0,0);
  }
}
export class Institution{
  constructor(
      public PkInstitution:number,
      public Name:string,
      public ShortName:string,
      public Phone:string,
      public Email:string,
      public FkUser:number
   ){}
}
export class Specialty{
  constructor(public PkSpecialty:number,
            public Name:string,
            public License:string,
            public Valid:string,
            public Liability:string,
            public LiaValid:string,
            public Dentist:number
   ){}
}

export class Message{
  constructor(
            public PkMessage:number,
            public FkUser:number,
            public Title:string,
            public Message:string,
            public Date:string,
            public touser:User,
            public tolink:string,
            public fromuser:User,
            public fromlink:string,
            public Check:boolean,
            public Label:string = '',
            public Sended:boolean = true
   ){}
}

export class Page{
  constructor(public total:number,
              public per_page:number,
              public current_page:number,
              public last_page:number,
              public data:any[] = null){}
}

export class Payment{
  constructor(public FkUser:number,
              public StripeId:string,
              public SquareId:string,
              public StripeSubscription:string,
              public PeriodEnd:string,
              public Status:string,
              public Alias:string){}
}


