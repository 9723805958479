import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {BaseModel} from './model/base.model';
import {UserModel} from './model/user.model';
import { BaseService } from './services/base.service';
import {ApiService} from './services/api.service';
import { AppComponent } from './app.component';
import {LoginComponent} from './login/login.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip'; 
import { MatInputModule} from '@angular/material';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { NavigationComponent } from './navigation/navigation.component';
import { ProfesionalistComponent } from './profesionalist/profesionalist.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SearchBoxComponent } from './search-box/search-box.component';
import { ProFormComponent } from './pro-form/pro-form.component';
import { OfficeFormComponent } from './office-form/office-form.component';
import { PaymentComponent } from './payment/payment.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { OfficeListComponent } from './office-list/office-list.component';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { TitleBoxComponent } from './title-box/title-box.component';
import { OfficeDetailComponent } from './office-detail/office-detail.component';
import { ProDetailComponent } from './pro-detail/pro-detail.component';
import { MyListComponent } from './my-list/my-list.component';
import { MessageListComponent } from './message-list/message-list.component';
import { MessageDetailComponent } from './message-detail/message-detail.component';
import { FooterComponent } from './footer/footer.component';
import { LoginMobileComponent } from './login-mobile/login-mobile.component';
import { BackNavComponent } from './back-nav/back-nav.component';
import { SendMessageComponent } from './send-message/send-message.component';
import {PaymentConfirmComponent} from './payment-confirm/payment-confirm.component';
import {AdminPaymentComponent} from './admin-payment/admin-payment.component';
import { CKEditorModule } from 'ng2-ckeditor';
import * as $ from 'jquery';
import { ConnectSmsComponent } from './connect-sms/connect-sms.component';
import { PhonePipe } from './phone.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FullImageComponent } from './full-image/full-image.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>)  = {};
export function tokenGetter() {
  return localStorage.getItem('access_token');
}


export const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path:'loginmobile', component:LoginMobileComponent},
  { path:'loginform', component:LoginFormComponent},
  { path:'listprof', component:ProfesionalistComponent},
  { path:'listprof/:page', component:ProfesionalistComponent},
  { path:'listoffices', component:OfficeListComponent},
  { path:'proform', component:ProFormComponent},
  { path:'proform/:id', component:ProFormComponent},
  { path:'officeform', component:OfficeFormComponent},
  { path:'officeform/:id', component:OfficeFormComponent},
  { path:'payment/:id', component:PaymentComponent}, 
  { path:'paymentconfirm/:id', component:PaymentConfirmComponent}, 
  { path:'officedetail/:id', component:OfficeDetailComponent},
  { path:'prodetail/:id', component:ProDetailComponent},
  { path:'mylist/:id', component:MyListComponent},
  { path:'messagelist', component:MessageListComponent},
  { path:'messagelist/list', component:MessageListComponent},
  { path:'messagedetail/:id', component:MessageDetailComponent},
  { path:'messagedetail', component:MessageDetailComponent},
  { path:'admin/payment', component:AdminPaymentComponent},
  { path:'admin/sms', component:ConnectSmsComponent},
  { path:'fullimage/:img', component:FullImageComponent}  
]

@NgModule({
  declarations: [
    AppComponent,LoginComponent,NavigationComponent,ProfesionalistComponent, SearchBoxComponent,
    ProFormComponent, OfficeFormComponent, PaymentComponent, PaymentConfirmComponent, 
    LoginFormComponent, OfficeListComponent, TitleBoxComponent, OfficeDetailComponent,
    ProDetailComponent, MyListComponent, MessageListComponent, MessageDetailComponent, FooterComponent, 
    LoginMobileComponent, BackNavComponent, SendMessageComponent,AdminPaymentComponent, ConnectSmsComponent, PhonePipe, FullImageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgSelectModule,
    TruncateModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatNativeDateModule,MatDialogModule,
    BrowserAnimationsModule,
    CKEditorModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    NgxMaskModule.forRoot(options),
    NgbModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [ new RegExp('^null$')],
        blacklistedRoutes: [ new RegExp('^null$')]
      }
    })
  ],
  providers: [ApiService, BaseModel, UserModel, BaseService],
  bootstrap: [AppComponent]
})
export class AppModule { }
