<div class="wrapper wrapper_lg air air_horz">
    <!-- only mobile -->
    <div class="row">
        <!--
        <div class="flex-column col-lg-4 l-grey">
              <app-login-form></app-login-form>           
        <div> -->
        <div class="col-lg-8 l-white">  
            <div class="login ml-lg-10 mt-lg-10">
                <img src="./assets/img/logos/logo_vector.svg" width="300" height="auto"/>
                <div class="flex-column justify-content-center">
                       <p class="h5 text-center ml-3 mr-3"><br><b>Connect with Dental Markets</b></p>
                    <p class="text-center ml-3 mr-3"><small>
TransForma Connect is a platform of introduction. Select your specialties and create your profile. Dental professionals will be contacted directly by practices seeking their skills.</small></p>
                        <button class="btn w-100 btn-lg btn-lg btn-primary mt-3" routerLink="/officeform">&nbsp;&nbsp;Create Office Profile&nbsp;&nbsp;</button>
                         <button class="btn w-100 btn-lg btn-outline-primary mt-3" routerLink="/proform">Create Professional Profile</button>
                         <div class="d-flex w-100 mt-4 justify-content-center">
                          <a href="javascript:void(0)" class="h4 text-primary" routerLink="/loginform">Login</a>
                         </div>
                         <div class="d-flex w-100 mt-4 justify-content-center">
                            <div>
                              <img src="assets/img/ui/prof1.jpg" width="auto" height="140" /> 
                              <img src="assets/img/ui/prof4.jpg" width="auto" height="140" />
                            </div>
                       </div>                       
              </div>
               <p class="text-center mt-3"><small class="text-muted">TransForma LLC {{year}} V2.8</small></p>
            </div>
             <p>&nbsp;</p>
             <p>&nbsp;</p>
             <p>&nbsp;</p> 
             <p>&nbsp;</p>              
        </div>                      
    </div>  
</div>
