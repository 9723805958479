<app-back-nav class="d-lg-none d-flex"></app-back-nav> 
<div id="bighome" class="wrapper wrapper_lg air air_horz">
    <div class="row">
        <div class="col-lg-8 l-white">  
            <div class="login ml-lg-10 mt-lg-10">
                <div class="flex-column">
                	<div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
                	<div class="row mt-8 d-none d-lg-flex ml-1"><!-- ROW -->
	                   <div class="col-md-9">
	                        <span class="h3">Create Office Profile</span>	                         
	                    </div>
	                   <div class="col-md-3">&nbsp;</div> 
	                </div>
	                <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
                    <div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9">
	                       <div class="form-group">
	                       		<img src="{{office.Image}}" id="Image" style="max-height:200;max-width: auto">
	                       		<a href="javascript:document.getElementById('profimage').click()" id="camera"><i class="fas fa-camera fa-3x ml-2"></i></a> 
	                       		<span class="ml-2">Profile Image</span>
	                       		<input type="file" name="profimage" class="form-control" id="profimage" accept=".jpg,.png" (change)="loadFile($event)" style="display: none">
						   </div>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<!--<div class="row mt-8 ml-1"> ROW
	                    <div class="col-md-9">
	                       <div class="form-group">
	                       		<label for="company">Company (DBA)</label>
	                       		<input type="text" name="address" class="form-control" id="company" placeholder="Dental Co.">
	                       		<small class="errormsg" id="company_error">Company is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row ml-1"><!-- ROW -->
	                    <div class="col-md-6">
	                       <div class="form-group">
	                       		<label for="name">Practice Name</label>
	                       		<input type="text" name="name" class="form-control" id="name" [(ngModel)]="office.Name">
	                       		<small class="errormsg" id="Name_error">Practice is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-md-3">
	                       <div class="form-group">
	                       		<label for="years">Open Since</label>
	                       		<select class="form-control" name="years" id="years" [(ngModel)]="office.Years">
									<option *ngFor="let year of years" value="{{year}}">{{year}}</option>
						        </select>
	                       		<small class="errormsg" id="Years_error">Year established is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-3">&nbsp;</div> 
	                </div><!-- END ROW -->
	                <div class="row mt-8 ml-1"><!-- ROW -->
	                    <div class="col-md-9">
	                       <div class="form-group">
	                       		<label for="contact">Contact Name</label>
	                       		<input type="text" name="address" class="form-control" id="contact" [(ngModel)]="office.Contact">
	                       		<small class="errormsg" id="Contact_error">Contact name is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->	                	
	                    <div class="col-md-4">
	                       <div class="form-group">
	                       		<label for="officeTel">Office Telephone</label>
	                       		<input type="text" name="officeTel" class="form-control" id="officeTel" mask="(000) 000-0000" [(ngModel)]="office.Telephone">
	                       		<small class="errormsg" id="Telephone_error">Office telephone is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-md-5">
	                       <div class="form-group">
	                       		<label for="mobile">Mobile (optional)</label>
	                       		<input type="text" name="mobile" class="form-control" id="mobile" mask="(000) 000-0000" [(ngModel)]="office.Mobile">	                       		
						   </div>
	                    </div>
	                    <div class="col-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->
						<div class="col-md-4">
	                       <div class="form-group">
	                       		<label for="officeAddress">Contact Email</label>
	                       		<input type="email" name="contactmail" class="form-control" id="contactmail"  [(ngModel)]="office.Email">
	                       		<small class="errormsg" id="Email_error">Contact email is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-md-5">
	                       <div class="form-group">
	                       		<label for="officeAddress">Office Address</label>
	                       		<input type="text" name="Address" class="form-control" id="Address"  [(ngModel)]="office.Address" (change)="onAddress()" autocomplete="new-password">
	                       		<input type="hidden" id="Latitude" name="Latitude" [(ngModel)]="office.Latitude">
	                       		<input type="hidden" id="Longitude" name="Latitude" [(ngModel)]="office.Longitude">
	                       		<small class="errormsg" id="Address_error">Office Address is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row ml-1"><!-- ROW -->
						  <div class="col-md-5">
	                       <div class="form-group">
	                       		<label for="City">City</label>
	                       		<input type="text" name="city" id="city" class="form-control" id="city" [(ngModel)]="office.City">
	                       		<small class="errormsg" id="City_error">City is mandatory</small>
						   </div>
	                    </div>	                   
	                    <div class="col-md-2">
	                       <div class="form-group">
	                       		<label for="state">State</label>
	                       		<select class="form-control" name="state" id="state" [(ngModel)]="office.State">
									<option value="AL">Alabama</option>
						            <option value="AK">Alaska</option>
						            <option value="AZ">Arizona</option>
						            <option value="AR">Arkansas</option>
						            <option value="CA">California</option>
						            <option value="CO">Colorado</option>
						            <option value="CT">Connecticut</option>
						            <option value="DE">Delaware</option>
						            <option value="DC">District Of Columbia</option>
						            <option value="FL" selected>Florida</option>
						            <option value="GA">Georgia</option>
						            <option value="HI">Hawaii</option>
						            <option value="ID">Idaho</option>
						            <option value="IL">Illinois</option>
						            <option value="IN">Indiana</option>
						            <option value="IA">Iowa</option>
						            <option value="KS">Kansas</option>
						            <option value="KY">Kentucky</option>
						            <option value="LA">Louisiana</option>
						            <option value="ME">Maine</option>
						            <option value="MD">Maryland</option>
						            <option value="MA">Massachusetts</option>
						            <option value="MI">Michigan</option>
						            <option value="MN">Minnesota</option>
						            <option value="MS">Mississippi</option>
						            <option value="MO">Missouri</option>
						            <option value="MT">Montana</option>
						            <option value="NE">Nebraska</option>
						            <option value="NV">Nevada</option>
						            <option value="NH">New Hampshire</option>
						            <option value="NJ">New Jersey</option>
						            <option value="NM">New Mexico</option>
						            <option value="NY">New York</option>
						            <option value="NC">North Carolina</option>
						            <option value="ND">North Dakota</option>
						            <option value="OH">Ohio</option>
						            <option value="OK">Oklahoma</option>
						            <option value="OR">Oregon</option>
						            <option value="PA">Pennsylvania</option>
						            <option value="RI">Rhode Island</option>
						            <option value="SC">South Carolina</option>
						            <option value="SD">South Dakota</option>
						            <option value="TN">Tennessee</option>
						            <option value="TX">Texas</option>
						            <option value="UT">Utah</option>
						            <option value="VT">Vermont</option>
						            <option value="VA">Virginia</option>
						            <option value="WA">Washington</option>
						            <option value="WV">West Virginia</option>
						            <option value="WI">Wisconsin</option>
						            <option value="WY">Wyoming</option>
	                       		</select>
	                       		<small class="errormsg" id="State_error">State is mandatory</small>
						   </div>
	                    </div>
	                     <div class="col-md-2">
	                       <div class="form-group">
	                       		<label for="zip">Zip</label>
	                       		<input type="text" name="zip" class="form-control" id="zip" [(ngModel)]="office.Zip">
	                       		<small class="errormsg" id="Zip_error">Zip is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-3">&nbsp;</div> 
	                </div><!-- END ROW -->
	                <div class="row ml-1"><!-- ROW -->
	                    <div class="col-md-5">
	                       <div class="form-group">
	                       		<label for="email">Email <small>(to be used as username)</small></label>
	                       		<input type="email" name="email" class="form-control" id="email" [(ngModel)]="user.email">
	                       		<small class="errormsg" id="email_error">Email is mandatory</small>
						   </div>
	                    </div>
	                    <div class="col-md-4">
	                       <div class="form-group">
	                       		<label for="password">Password</label>
	                       		<input type="password" name="lastname" class="form-control" id="password"  [(ngModel)]="user.password">
	                       		<small class="errormsg" id="password_error">Should have at least 8 characters</small>
						   </div>
	                    </div>
	                    <div class="col-md-3 col-xs-0">&nbsp;</div> 
	                </div><!-- END ROW -->
	                <div class="row ml-1"><!-- ROW -->                    
	                    <div class="col-md-9">
	                       <div class="form-group">
	                       		<label for="services">Services and Specialties</label>
	                       		<textarea rows="5" name="services" class="form-control" id="services" [(ngModel)]="office.Services"></textarea>
						   </div>
	                    </div>
	                    <div class="col-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8">
						<div class="flex-column justify-content-center ml-4 ml-lg-5 mr-4">
							<small class="errormsg">{{generalError}}</small>
							<div id="c-alerts__area"></div>
							<button class="btn w-100 btn-large btn-secondary" (click)="save()">{{submitmsg}}</button>						
							<p><small class="text-muted">By clicking this button you agree to TransForma's <a href="javascript:void(0)" data-toggle="modal" data-target="#disclaimer">Policy and terms of use</a></small></p>
							<p class="text-center"><small><a href="javascript:void(0)" data-toggle="modal" data-target="#unsubscribe">Unsubscribe</a></small></p>
		              </div>
					</div> <!-- separa -->
					<div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
                </div>
            </div>
        </div>
        <div class="flex-column col-lg-4 l-grey d-none d-lg-flex">
           <app-login-form></app-login-form>
        <div>
    </div>
</div>
<app-footer class="d-none d-lg-block"></app-footer>

<div class="modal" tabindex="-1" role="dialog" id="disclaimer">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">TransForma Terms and Conditions</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Welcome to <strong>TransForma Connect</strong>, the service for Dental Service Organizations (DSO’s), and Multi &amp; Single Practice Dental Organizations to identify and contact Clinical &amp; Non Clinical Professionals, operated by TransForma, LLC.</p>

<p>By accessing <strong>TransForma Connect</strong> via <a href="http://TransForma.us">TransForma.us</a>, <a href="http://TransForma.us/connect">TransForma.us/connect</a>, or <a href="http://transformaconnect.com">TransFormaConnect.com</a>, you agree to be bound by the Terms of Use (this agreement), whether or not you register as a member. If you wish to become a member and make use of the <strong>TransForma Connect</strong> service, please read these Terms of Use. The term "Website" is deemed to refer to using of the Service by means of a computer, a mobile device, or a mobile application.</p>

<ol>
	<li><strong>Acceptance of Terms of Use Agreement.</strong>

	<ul>
		<li>This Agreement is an electronic contract that establishes the legally binding terms you must accept to use the Website and to become a "Member." For purposes of this Agreement, the term "Member" means a person who provides information to TransForma, LLC on the Website or to participate in the Service in any manner, whether such person uses the Service as a free member or a subscriber. You acknowledge and agree that Members of <strong>TransForma Connect</strong> will be part an online directory&nbsp; owned by TransForma, LLC, and that Professional profiles on the Website will be viewable by paying Company subscribers who will be able to communicate directly with Professional subscribers.</li>
		<li>By accessing the Website or using the Service, you accept this Agreement and agree to the terms, conditions, and notices contained or referenced herein and consent to have this Agreement and all notices provided to you in electronic form. This Agreement may be modified by TransForma, LLC from time to time, such modifications to be effective upon posting by TransForma, LLC on the <strong>TransForma Connect</strong> websites.</li>
		<li>By using the Service, you consent to receive this Agreement in electronic form. To withdraw this consent, you must cease using the Service and terminate your account.</li>
	</ul>
	</li>
	<li><strong>Eligibility.</strong>
	<ul>
		<li>By accessing and using the Website, you represent and warrant that you have the right, authority, and capacity to enter into this Agreement and to abide by all of the terms and conditions of this Agreement. If you become a Member, you represent and warrant that you possess the credentials as stated in your profile. If you use the Service from outside of the United States, you are responsible for complying with the laws and regulations of the territory from which you access or use the Website and Service.</li>
	</ul>
	</li>
	<li><strong>Term and Termination.</strong>
	<ul>
		<li>This Agreement will remain in full force and effect while you use the Service and/or are a Member.</li>
		<li>You may change or cancel your membership at any time, for any reason, by pressing the “unsubscribe profile” link on the bottom of the profile page. If you cancel your membership, we ask that you provide a reason for your cancellation. If you cancel your subscription, TransForma, LLC requires a reasonable amount of time to process the action. If you cancel a subscription, you will enjoy subscription benefits until the end of your then-current subscription commitment, following which your subscription benefits will expire. However, in no event will you be eligible for a refund of any portion of the subscription fees paid for the then-current subscription commitment. Canceling a subscription does not automatically cancel your membership. If you are a subscriber and you cancel your subscription but not your membership, unless you elect to hide your profile, you will continue to be a Member in the Service and Companies may view your profile. If you hide your profile, Companies will not be able to view your profile until you "unhide" your profile. If you cancel your membership, your profile will be removed, and Companies will not be able to view your profile. You will be able to use your current registration information to "unhide" your profile and reactivate your membership for an unspecified period. A Member can hide their profile or cancel their membership and remove their profile at any time by following the instructions contained on the "Account Settings" page on the Website.</li>
		<li>TransForma, LLC may terminate or suspend your subscription and/or membership in the Service at any time without notice if TransForma, LLC believes that you have breached this Agreement, including, but not limited to, by using the Website and Service for fraudulent use, engaging in prohibited or inappropriate communications or activities, and any breach of your representations and warranties. Upon such termination or suspension, you will not be entitled to a refund of any unused subscription fees, if applicable. TransForma, LLC is not required to disclose, and may be prohibited by law from disclosing, the reason for the termination or suspension of your account.</li>
		<li>After your membership or subscription is terminated for any reason, all terms of this Agreement survive such termination, and continue in full force and effect, except for any terms that by their nature expire or are fully satisfied.</li>
	</ul>
	</li>
	<li><strong>Limitation o</strong><strong>f Liability.</strong>
	<ul>
		<li>To the fullest extent allowed by applicable law, in no event will TransForma, LLC, its affiliates, business partners, licensors, or service providers be liable to you or any third party for any indirect, reliance, consequential, exemplary, incidental, special, or punitive damages, including, without limitation, damages for loss or corruption of data or programs, service interruptions, and procurement of substitute services, even if TransForma, LLC has been advised of the possibility of such damages. Notwithstanding anything to the contrary contained herein, TransForma LLC's liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to TransForma, LLC for the service during the term of membership. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the Website or Service or the terms of this Agreement is forever null and void.</li>
	</ul>
	</li>
	<li><strong>Indemnity by You.</strong>
	<ul>
		<li>You agree to indemnify and hold TransForma, LLC, its subsidiaries &amp; affiliates, and its and their officers, agents, partners, and employees, harmless from any loss, liability, claim, or demand, including reasonable attorney's fees, made by any third party due to or arising out of your breach of or failure to comply with this Agreement, including any breach of your representations and warranties contained herein, any information or content you enter on the Website or as a result of the Service, and the violation of any law or regulation by you. TransForma, LLC reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with the TransForma, LLC in connection therewith.</li>
	</ul>
	</li>
	<li><strong>Entire Agreement; Other.</strong>
	<ul>
		<li>This Agreement, with any guidelines or rules that are separately posted for services on the Website, contains the entire agreement between you and TransForma, LLC regarding the use of the Website and/or the Service. If any provision of this Agreement is held invalid, the remainder of this Agreement shall continue in full force and effect. The failure of TransForma, LLC to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. You agree that your online account is non-transferable and all of your rights to your profile or contents within your account terminate upon your death. No agency, partnership, joint venture, or employment with TransForma, LLC is created as a result of this Agreement and you may not make any representations or bind TransForma, LLC in any manner.</li>
	</ul>
	</li>
</ol>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="unsubscribe" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Unsubscribe from service</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
	      <div class="col-md-8 col-xs-4">Are you sure you want to unsubscribe?</div>
	    </div>
	    <div class="row">
	      <div class="col-md-12 col-xs-4">
	      	<div class="form-group">
           		<label for="unsubs">Can you give us a reason?</label>
           		<textarea class="form-control" id="unsubs" [(ngModel)]="reason"></textarea>
		   </div>
	      </div>
	    </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="unsubscribe()">Send</button>
      </div>
    </div>
  </div>
</div>