import { Component, OnInit } from '@angular/core';
import {ApiService} from '../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import {User, Payment, Office} from '../model/dto'

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.css']
})
export class PaymentConfirmComponent implements OnInit {
	 public PkUser:number;
	 public user:User
	 public payment:Payment;
   public office:Office;
  subscription:any;

  constructor(private _api:ApiService,
    private route:ActivatedRoute, private _router:Router) { }

  ngOnInit() {
  	 this.subscription = this.route.params.subscribe(params => {
         this.PkUser = Number.parseInt(params['id']);
         if(!isNaN(this.PkUser))
           this._api.getPayment(this.PkUser)
              .subscribe(result => this.onPayment(result));

       });
  }

  onPayment(data:object){
  	this.payment = data as Payment;
  	this.user = data['user'] as User;
    if(this.user.FkType==2){
      this._api.getOfficeByUser(this.PkUser)
         .subscribe(result => this.onOffice(result));
    }
  }

   onOffice(data:object){
    //console.log(data);
    this.office = data as Office;
    this.office.Image = this._api.domain+'images/proffesionals/'+this.office.Image;
    this.user = this.office.user;
    this.user.password = '********'
  }
}
