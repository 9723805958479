import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-nav',
  templateUrl: './back-nav.component.html',
  styleUrls: ['./back-nav.component.css']
})
export class BackNavComponent implements OnInit {

  constructor(private _location:Location) { }

  ngOnInit() {
  }

   goBack(){
  	this._location.back();
  }
}
