<app-navigation></app-navigation> 

<app-title-box title="Message detail"></app-title-box>

<div class="wrapper wrapper_lg air air_horz">
    <div class="row " *ngIf="message">
        <div class="col-lg-7 l-white">  
            <div class="login ml-lg-10 mt-lg-10">
                <div class="flex-column">
                	<div class="row mt-8">
                    <div class="col-12">
                    <a href="javascript:void(0)" [routerLink]="fromlink">From: {{fromname}}</a>
                    </div>
                  </div> <!-- separa -->                	
                  <div class="row mt-8 ml-1"><!-- ROW -->                    
                    <div class="col-md-11 col-xs-3 mr-3 border border-dark bg-light">
                     		<p class="mt-2"><span>To: </span><i>{{nameTo}}</i> on {{message.Date | date:'short'}}</p>
                     		<p><span class="h5">{{message.Title}}</span></p>
                     		<div [innerHtml]="msg"></div>
                    </div>
                    <div class="col-md-1 col-xs-9">&nbsp;</div> 
					</div><!-- END ROW -->
           <div class="row mt-8 ml-1" *ngIf="answer"><!-- ROW if answer is pressed -->     
            <input type="text" class="form-control  col-md-11 col-xs-3 mr-3" name="title" id="title" [(ngModel)]="title"><br>            
            <textarea rows="5" name="message" class="form-control col-md-11 col-xs-3 mr-3" id="message" [(ngModel)]="response" placeholder="Message"></textarea><br>
            <span class="errormsg">{{errorMsg}}</span>
            <div class="col-md-1 col-xs-9">&nbsp;</div>            
            <div class="form-check mt-2 mb-2">
              <input type="checkbox" class="form-check-input" id="sendasmessage" value="1" [(ngModel)]="sendSms">
              <label class="form-check-label" for="exampleCheck1">Send as text message</label>
            </div>           
          </div><!-- END ROW -->          
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9">
	                    	<button *ngIf="recipientType==1" class="btn btn-dark mr-4" routerLink="/prodetail/{{toId}}">View profile</button>	
                        <button *ngIf="recipientType==2" class="btn btn-dark mr-4" routerLink="/officedetail/{{toId}}">View profile</button> 
                        <button class="btn btn-outline-dark ml-4" *ngIf="!answer && tome" (click)="answer=true">Answer</button> 
                        <button class="btn btn-outline-dark ml-4" *ngIf="answer && !status" (click)="sendMessage()">Send</button> 
	                    </div>

                      </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8">
            <div class="col-12">
  						<p>&nbsp;</p>
  						<p>&nbsp;</p>
  						<p>&nbsp;</p>
  						<p>&nbsp;</p>
  					</div>
          </div> <!-- separa -->
                </div>
           
        </div>
    </div>
</div>
<app-footer></app-footer>

<div class="modal" tabindex="-1" role="dialog" id="msg">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Message sent</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{status}}</p>
        <p>Please go to messages to see all.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>