import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Location } from '@angular/common';
import { User } from '../model/dto';
import { UserModel } from '../model/user.model';
import { BaseModel } from '../model/base.model';
import { fabric } from 'fabric';

@Component({
  selector: 'app-full-image',
  templateUrl: './full-image.component.html',
  styleUrls: ['./full-image.component.css']
})
export class FullImageComponent implements OnInit {
	subscription:any;
	public image:string;
  canvas: any;
  public width = 400;
  public height = 400;
  public imgHeight:number;
  public imgWidth:number;
  public mainImage;
  public imgAngle:number;
  public imgName:string;
  public user:User;
  public isMe:boolean = false;
  public isMobile:boolean = false;

  constructor(private _api:ApiService, private route:ActivatedRoute,
    private _location:Location, private _userModel:UserModel, private _baseModel:BaseModel) {
    this.user = this._userModel.user;
    this.subscription = this.route.params.subscribe(params => {
         if(!params['img']) return;
         var tmp = params['img'].split('-');
         this.isMe = ((tmp[0] == this.user.PkUser) || this.user.isAdmin);
         var img:string = tmp[1];
         if(img.length>4){
           var domain = this._api.domain;
           this.image = domain+'images/proffesionals/'+img+'?'+Math.random();
           this.imgName = img;
         }             
       });
     // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) this.isMobile = true;

  }

  ngOnInit() {
    this.canvas = new fabric.Canvas('myCanvas');
    //this.canvas.add(new fabric.IText('Hello Fabric!'));
    fabric.Image.fromURL(this.image, (oImg) => {
      //var canvas = document.getElementById('myCanvas');
      oImg.selectable = false;
      this.imgWidth = oImg.width;
      this.imgHeight = oImg.height; 
      /*oImg.centeredRotation = true;
      oImg.originX = "center",  
      oImg.originY = "center"
      oImg.angle = 180;*/
      this.canvas.setHeight(this.imgHeight);
      this.canvas.setWidth(this.imgWidth);

     //console.log(oImg.width+'/'+oImg.height) 
      this.canvas.add(oImg);
      this.mainImage = oImg;
      this.mainImage.angle = this.imgAngle = 0
      console.log(this.mainImage)
    },{ crossOrigin: 'Anonymous' });

  }

  rotate(){
     //var curAngle = this.mainImage.getAngle();
    //this.imgAngle +=90;
     this.mainImage.centeredRotation = true;
      this.mainImage.originX = "center",  
      this.mainImage.originY = "center"
      this.imgAngle+=90;
      if(this.imgAngle==360) this.imgAngle = 0
      this.mainImage.angle = this.imgAngle;
      this.mainImage.left = 0;
      this.mainImage.top = this.imgHeight/2;
      this.mainImage.left = this.imgWidth/2;
    //this.mainImage.angle = this.imgAngle;
    //this.mainImage.setAngle(0);
    this.canvas.renderAll();
    this.mainImage.set('angle', this.imgAngle);
    //console.log(this.mainImage)
  }

  closeWindow(){
    this._baseModel.setProp({name:'closeWindow', value:1})
  }
   saveImg(){    
     if (!this.canvas.toDataURL) {
      alert('This browser doesn\'t provide means to serialize canvas to an image');
    }
    else {
      //window.open(this.canvas.toDataURL('png'));
      //console.log(this.file)
      //http://localhost/ony-001-backend/public/inspections/33/1_1.png

      var options = {format:'image/jpeg', left:0, top:0, width:this.imgWidth, height:this.imgHeight}
      this._api.saveImage(this.canvas.toDataURL("image/jpeg", 1.0),this.imgName)
        .subscribe(result=>this.onSaved(result)) 
    }
  }

  onSaved(data:object){
    console.log(data)
  }

  goBack(){
  	this._location.back();
  }

}
