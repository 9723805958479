<div class="wrapper wrapper_lg air air_horz">
    <div class="row">
        <div class="flex-column col-lg-8 l-white justify-content-end">                     	
            <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
            <div class="row mb-4">
            	<div class="d-flex col-12 justify-content-center">
            		<span class="h3">Membership Benefits</span>
            	</div>
            </div> <!-- separa -->
            <div class="row mt-8 ml-1"><!-- ROW -->
            	<div class="col-1"></div>                    
                <div class="col-md-10">
                	<table border="1" class="w-100">
					 <tr style="border: 1px solid black">
						<td width="25%" style="padding: 10px;" align="center">Access to the TransForma dental professional database</td>
						<td width="25%" style="padding: 10px;" align="center">Instant phone, text, and email messaging to any professional or saved group</td>
						<td width="25%" style="padding: 10px;" align="center">Search by skills, experience, hourly rate, distance, etc.</td>
						<td width="25%" style="padding: 10px;" align="center">Full office confidentiality, search anonymously</td>
					  </tr>
					</table>
					<br>
                   <div class="form-group">
                   		<label class="h6">Payment options</label>
                   		<div class="form-check">
						  <input class="form-check-input" type="radio" name="payment" id="payment1" value="150">
						  <label class="form-check-label" for="payment1">
						   Monthly payment of $150
						  </label>
						</div>
						<div class="form-check">
						  <input class="form-check-input" type="radio" name="payment" id="payment2" value="425">
						  <label class="form-check-label" for="payment2">
						   3 month payment of $425
						  </label>
						</div>
						<div class="form-check">
						  <input class="form-check-input" type="radio" name="payment" id="payment3" value="800">
						  <label class="form-check-label" for="payment3">
						   6 month payment of $800
						  </label>
						</div>
						<div class="form-check">
						  <input class="form-check-input" type="radio" name="payment" id="payment4" value="1500" checked>
						  <label class="form-check-label" for="payment4">
						    Full year payment of $1500
						  </label>
						</div> 
				   </div>
                </div>
                <div class="col-md-1">&nbsp;</div> 
			</div><!-- END ROW -->
			<div class="row mt-8 ml-1 mb-2"><!-- ROW -->
				<div class="col-1">&nbsp;</div>                    
                <div class="d-flex col-md-10 justify-content-center">
					<i>Membership will renew automatically, and can be canceled at any time.</i>
				</div>
                <div class="col-md-1">&nbsp;</div> 
			</div><!-- END ROW -->
            <div class="row mt-8 ml-1"><!-- ROW --> 
            	<div class="col-1">&nbsp;</div>                    
                <div class="col-md-10">
                   <div class="form-group">
                   		<label for="cardnr">Card number</label>
                   		<div id="card-info" #cardInfo></div> 
				   </div>
                </div>
                <div class="col-md-1">&nbsp;</div> 
			</div><!-- END ROW -->
			 <div class="row mt-8 ml-1"><!-- ROW -->
			 	<div class="col-1">&nbsp;</div>   
                <div class="col-md-10">
                   <div class="form-group">
                   		<label for="name">Name on Card</label>
                   		<input type="text" name="name" class="form-control" id="name" placeholder="John Doe" [(ngModel)]="name">
                   		<small class="errormsg" id="name_error">Name is mandatory</small>
				   </div>
                </div>
                <div class="col-1">&nbsp;</div> 
			</div><!-- END ROW -->
			<div class="row mt-8 ml-1">
				<div class="col-1">&nbsp;</div>   
				 <div class="col-md-10">
                   <div class="form-group">
                   		<label for="address">Email</label>
                   		<input type="email" name="email" class="form-control" id="email" placeholder="some@email.com"  [(ngModel)]="email">
                   		<small class="errormsg" id="email_error">Email is mandatory</small>
				   </div>
                </div>
                <div class="col-1">&nbsp;</div> 
			</div><!-- ROW 
                <div class="col-md-9">
                   <div class="form-group">
                   		<label for="address">Billing Address</label>
                   		<input type="text" name="address" class="form-control" id="address" placeholder="1424 Whitehall Blvd">
                   		<small class="errormsg" id="address_error">Address is mandatory</small>
				   </div>
                </div>
                <div class="col-3">&nbsp;</div> 
			</div>
			<div class="row ml-1">
                <div class="col-md-9">
                   <div class="form-group">
                   		<label for="city">Billing City</label>
                   		<input type="text" name="name" class="form-control" id="city" placeholder="Orlando">
                   		<small class="errormsg" id="city_error">City is mandatory</small>
				   </div>
                </div>
                <div class="col-3">&nbsp;</div> 
            </div>
			<div class="row ml-1">
				  
               
                <div class="col-md-4">
                   <div class="form-group">
                   		<label for="state">Billing State</label>
                   		<select class="form-control" name="state" id="state">
							<option value="AL">Alabama</option>
				            <option value="AK">Alaska</option>
				            <option value="AZ">Arizona</option>
				            <option value="AR">Arkansas</option>
				            <option value="CA">California</option>
				            <option value="CO">Colorado</option>
				            <option value="CT">Connecticut</option>
				            <option value="DE">Delaware</option>
				            <option value="DC">District Of Columbia</option>
				            <option value="FL" selected>Florida</option>
				            <option value="GA">Georgia</option>
				            <option value="HI">Hawaii</option>
				            <option value="ID">Idaho</option>
				            <option value="IL">Illinois</option>
				            <option value="IN">Indiana</option>
				            <option value="IA">Iowa</option>
				            <option value="KS">Kansas</option>
				            <option value="KY">Kentucky</option>
				            <option value="LA">Louisiana</option>
				            <option value="ME">Maine</option>
				            <option value="MD">Maryland</option>
				            <option value="MA">Massachusetts</option>
				            <option value="MI">Michigan</option>
				            <option value="MN">Minnesota</option>
				            <option value="MS">Mississippi</option>
				            <option value="MO">Missouri</option>
				            <option value="MT">Montana</option>
				            <option value="NE">Nebraska</option>
				            <option value="NV">Nevada</option>
				            <option value="NH">New Hampshire</option>
				            <option value="NJ">New Jersey</option>
				            <option value="NM">New Mexico</option>
				            <option value="NY">New York</option>
				            <option value="NC">North Carolina</option>
				            <option value="ND">North Dakota</option>
				            <option value="OH">Ohio</option>
				            <option value="OK">Oklahoma</option>
				            <option value="OR">Oregon</option>
				            <option value="PA">Pennsylvania</option>
				            <option value="RI">Rhode Island</option>
				            <option value="SC">South Carolina</option>
				            <option value="SD">South Dakota</option>
				            <option value="TN">Tennessee</option>
				            <option value="TX">Texas</option>
				            <option value="UT">Utah</option>
				            <option value="VT">Vermont</option>
				            <option value="VA">Virginia</option>
				            <option value="WA">Washington</option>
				            <option value="WV">West Virginia</option>
				            <option value="WI">Wisconsin</option>
				            <option value="WY">Wyoming</option>
                   		</select>
                   		<small class="errormsg" id="state_error">State is mandatory</small>
				   </div>
                </div>
                 <div class="col-md-5">
                   <div class="form-group">
                   		<label for="zip">Zip</label>
                   		<input type="text" name="zip" class="form-control" id="zip" placeholder="32852">
                   		<small class="errormsg" id="experience_error">Zip is mandatory</small>
				   </div>
                </div>
                <div class="col-3">&nbsp;</div> 
            </div><!-- END ROW -->
            
            <div class="row mt-8">
            	<div class="col-md-4">&nbsp;</div>
            	<div class="col-md-4"><img src="assets/img/logos/powered_by_stripe.png"></div>
            	<div class="col-md-4">&nbsp;</div>
            </div>
            <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
             <div class="row mt-8">
            	<div class="col-md-12">
            		<p><span class="errormsg">{{gralError}}</span></p>
            	</div>
            </div>
			<div class="row mt-8"><!-- ROW -->
                <div class="col-md-3">&nbsp;</div>
            	<div class="col-md-6">
                    <button class="btn btn-primary mt-3" (click)="validate()">Proccess the Aplication</button>
                    <button class="btn btn-outline-primary mt-3" routerLink="/">Cancel</button>
                </div>
                <div class="col-3">&nbsp;</div>
            </div><!-- separa -->
			<div class="row mt-8"><div class="col-md-12">&nbsp;</div></div> <!-- separa -->
        </div>
        <div class="flex-column col-lg-4 l-grey">
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <app-login-form></app-login-form>   
        <div>
    </div>
</div>
<app-footer></app-footer>