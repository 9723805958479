import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {UserModel} from '../model/user.model';
import { ApiService } from '../services/api.service';
import {User} from '../model/dto';

@Component({
  selector: 'app-admin-payment',
  templateUrl: './admin-payment.component.html',
  styleUrls: ['./admin-payment.component.css']
})
export class AdminPaymentComponent implements OnInit {
	subscription:any;
	public user:User;
	public code:string;
  public stripeId:string;
// http://localhost:4200/admin/payment?scope=read_write&code=ac_Dhb6v2Xb5XLNFtXLFQdK3pmcIyJW4Z4M
  constructor(private _api:ApiService,  
  			private route:ActivatedRoute, 
  			private _userModel:UserModel) { 
  	this.user = this._userModel.user;
  }

  ngOnInit() {
  	 
  	 this.subscription = this.route.queryParams.subscribe(params => {	  	 
	  	 this.code = params['code'];
	     if(this.code)
	        this._api.getStripeToken(this.code).subscribe(result => this.onStripeId(result));
	      else
	      	this._api.getStripeId().subscribe(result => this.onStripeId(result));
	  })    
  }

  onStripeId(data:object){
    //console.log(data);
  	this.stripeId = data['data']['stripe_user_id'] as string;
  }
}
