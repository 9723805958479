import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User,Office } from '../model/dto';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';

@Component({
  selector: 'app-office-detail',
  templateUrl: './office-detail.component.html',
  styleUrls: ['./office-detail.component.css']
})
export class OfficeDetailComponent implements OnInit {
	subscription:any;
	public office:Office = Office.getInstance();
  public user:User;
  public sendMessageOffices:boolean = false;
  public isAdmin:boolean = false;

    constructor(private _api:ApiService, private route:ActivatedRoute, 
    private router:Router, private _baseModel:BaseModel, private _userModel:UserModel) { 
      this.user = this._userModel.user
      //this.isAdmin = this.user.FkType == 4;
      this.isAdmin = (this.user.email=='admin@admin.com')
    }

  ngOnInit() {
  	 this.subscription = this.route.params.subscribe(params => {
         var id:number = parseInt(params['id']);
         if(!isNaN(id))
           this._api.getOffice(id)
              .subscribe(result => this.onOffice(result));
       });
  }

  onOffice(data:object){
    this.office = data as Office;
    this.office.Image = this._api.domain+'images/offices/'+this.office.Image;
  }

  addToMyList(){
    var PkOwner = this.user.PkUser;
    var PkUser = this.office.FkUser;
    this._api.addToMyList(PkUser, PkOwner)
      .subscribe(result => this.onFinish(result));
  }

  onFinish(data:object){
    this.router.navigate(['/mylist'])
  }
}
