<div class="container bg-white">
	<div class="row inside">
		<div class="w-100">			
			<p>&nbsp;</p>
			<span class="h5 ml-2">Connect your account to receive payments</span>
			<p>&nbsp;</p>
            <p class="ml-4"><a *ngIf="!stripeId" href="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_DiNleP5hislBWy4xQUa35p5joqmNzbKg&scope=read_write">
            <img src="assets/img/ui/blue-on-light.png"></a></p>
			<p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
		<div *ngIf="stripeId" class="card w-100 mt-4 mr-4 ml-4"> <!-- init card -->
          <div class="card-header">
            Account details
          </div>
          <div class="card-body">
            <h5 class="card-title">Transforma Connect account</h5>
            <p class="card-text">Current stripe ID {{stripeId}}</p>
            <p class="card-text">Current plans:
                <ul>
                    <li>Montly payment of $150</li>
                    <li>3 Month payment of $425</li>
                    <li>6 Month payment of $800</li>
                    <li>12 Month payment of $1500</li>
                </ul>
            <div class="d-flex justify-content-end"></div>
           
		</div>
			 <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
	</div>
</div>