import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User,Message,Proffesional,Office } from '../model/dto';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'app-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.css']
})
export class MessageDetailComponent implements OnInit {
 subscription:any;
 public nameTo:string;
 public toId:number;
 public recipientType:number;
private prof:Proffesional
private office:Office;
public message:Message;
public msg:SafeHtml //sanitized
private user:User;
public fromlink:string;
public fromname:string;
public response:string;
public title:string;
public errorMsg:string;
public status:string;
public answer:boolean = false;
public tome:boolean = false;
public sendSms:boolean = false;

  constructor(private _api:ApiService, private route:ActivatedRoute, 
    private router:Router, private _baseModel:BaseModel, private _userModel:UserModel,
    private sanitized: DomSanitizer) { 
    this.user = this._userModel.user;
  }

  ngOnInit() {
  	 this.subscription = this.route.params.subscribe(params => {
         var id:number = parseInt(params['id']);
         if(!isNaN(id))
           this._api.getMessage(id)
              .subscribe(result => this.onMessage(result));
       });
  }
  onMessage(data:object){
  	this.message = data[0] as Message;
  	var id = this.message['touser']['PkUser']
  	this.message.Message = this.message.Message.replace('\n', '<br>');
    this.msg = this.sanitized.bypassSecurityTrustHtml(this.message.Message+'<p>&nbsp;</p>');
  	this.title = this.message.Title
   
    var type = this.message['fromuser']['FkType']
    var f =(type==1)?this.message['fromuser']['proffesional']:this.message['fromuser']['office']; //shortcut
    this.fromname = (type==1)?f['FirstName']+' '+f['Middle']+' '+f['LastName']:f['Name']+' ('+f['Contact']+')';
    this.fromlink = (type==1)?'/prodetail/'+f['PkProffesional']:'/officedetail/'+f['PkOffice'];
    if(this.message['touser']['FkType']==1){
  		this._api.getProffesionalByUser(id)
  			.subscribe(result => this.onProffesional(result));
  	} else {
  		this._api.getOfficeByUser(id)
  			.subscribe(result => this.onOffice(result));
  	}
    if(id == this.user.PkUser) {
      this.tome = true
      this._api.markAsView(this.message.PkMessage)
        .subscribe(result => this.marked(result));
        //data from sender
       /*this._api.getSender(this.message.FkUser)
        .subscribe(result => this.sender(result));*/
    } else {
      this.tome = false
    }
    //console.log('es para mi? '+this.tome);
  }

  sender(data:object){
    console.log(data)
  }

  marked(data:object){
    this._baseModel.setProp({name:'read', value:1});
  }

  onProffesional(data:Object){
  	this.prof = data as Proffesional;
  	this.nameTo = this.prof.FirstName+' '+this.prof.LastName;
  	this.recipientType = 1;
  	this.toId = this.prof.PkProffesional
  }

  onOffice(data:object){
    this.office = data as Office;
    this.nameTo = this.office.Name;
  	this.recipientType = 2;
  	this.toId = this.office.PkOffice
  }

  sendMessage(){
    this.errorMsg = '';
    //get information about destinatary
    if(!this.title || this.title.length<3) this.errorMsg = "Title is mandatory";
    else if(!this.response || this.response.length<3) this.errorMsg = "Message is mandatory";
    console.log(this.sendSms.toString())
    if(this.errorMsg=='') {  
       this._api.sendMessage(this.user.PkUser, this.message.FkUser, this.title, this.response, this.sendSms.toString())
          .subscribe(result=>this.onMessageSent(result));
     }
  } 

  onMessageSent(data:object){
    var it:User = this.message.touser
    this.answer = true;
    var to:string = (it.FkType==1)?it['proffesional'].FirstName+' '+it['proffesional'].FirstName:it['office'].Name;
    this.status = "Message sent !!";
     window['closeModal']('msg', 'show');
     this.answer = false;
  }

}
