import { Injectable, Component, ReflectiveInjector, OnInit } from '@angular/core';
import { HttpModule, Http, Response, XHRBackend,ConnectionBackend,BrowserXhr,
        ResponseOptions, XSRFStrategy, BaseResponseOptions, CookieXSRFStrategy,
        RequestOptions, BaseRequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
//import { ErrorObservable } from 'rxjs';
import { HttpClient } from '../services/httpclient';
import {BaseModel} from '../model/base.model';
import { throwError } from 'rxjs';

export class BaseService implements OnInit {

    //dev
    protected _domain:string;// = "http://dev.marketplace.3818.com.ar/";
    protected imagesDomain:string; // = "http://dev.marketplace.3818.com.ar/admin/images/";
    protected uploadRoute:string; //= 'http://dev.marketplace.3818.com.ar/admin/images/***/upload.php';
    protected excelUploadRoute:string; // = 'http://dev.marketplace.3818.com.ar/admin/php/proccess.php';
    
    protected jwt: string;
    protected decodedJwt: string;
    protected _httpClient:HttpClient;
    protected _http:Http;
    protected _baseModel:BaseModel;

    constructor() {
        this._domain = window["gateway"];
        this.imagesDomain = window["imagesDomain"];
        this.uploadRoute = window["uploadRoute"];
        this.excelUploadRoute = window["excelUploadRoute"];

        let injector = ReflectiveInjector.resolveAndCreate([Http,HttpClient,BrowserXhr,BaseModel,
            { provide: ConnectionBackend, useClass: XHRBackend },
            { provide: ResponseOptions, useClass: BaseResponseOptions },
            { provide: XSRFStrategy, useValue: new CookieXSRFStrategy('csrftoken', 'X-CSRFToken')},
            { provide: RequestOptions, useClass: BaseRequestOptions }]);
         this._http = injector.get(Http);
         this._httpClient = injector.get(HttpClient);
         this._baseModel =  injector.get(BaseModel);
    }

    ngOnInit(){}

    public get domain():string
    {
        return  this._domain;
    }

    public set domain(value:string)
    {
        this._domain = value;
    }

    get uploadPath():string{
      //return window['uploadRoute'];
     return this.uploadRoute
    }
    get excelUploadPath():string{
       //return window['excelUploadRoute'];
      return this.excelUploadRoute
    }

    //helper functions
      //crea la fecha en formato YYYY-mm-dd hh-mm-ss
    createDate(now:Date){
       var year = now.getFullYear();
       var month =((now.getMonth()+1)<10)?"0"+(now.getMonth()+1):now.getMonth()+1;
       var day = (now.getDate()<10)?"0"+now.getDate():now.getDate();
       var hour = (now.getHours()<10)?'0'+now.getHours():now.getHours();
       var minutes = (now.getMinutes()<10)?'0'+now.getMinutes():now.getMinutes();
       var seconds = "00";
       return year+"-"+month+"-"+day+" "+hour+":"+minutes+":"+seconds;
    }

    protected forceLogin(){
         //for login
         localStorage.clear();
         window.location.reload(true);
    }

  protected handleError(error: any, caught: Observable<Object[]>) {
     //for(var prop in error) console.error(prop+":"+error[prop]);
     console.log(error);
     //alert(error)
     if(error.status==401 && error['url'].indexOf('login')==-1) {
         localStorage.clear();
         //document.location.href = "/connect/";
     }
     if(error.status==500 && error['_body']){
         //for(var prop in  error['_body']) console.log(prop+": "+error['_body'][prop])
         if(error['_body'].indexOf('token could not be parsed')>0) {
            localStorage.clear();
            //document.location.href = "/connect/";
         }
     }
    var flag:object = JSON.parse(error._body);
    if(flag['status']=='error'){
        if(flag['email'] && flag['email'][0])
            window['addAlerts']('danger', 'Error',flag['email'][0]);
    }
     //alert('Error doing the operation\n'+error['statusText']) 
      // window['addAlerts']('danger', 'Error', error['StatusText']);
    try{
        //alert(error) 
        return throwError(error);
        // return Observable.throw(error || 'Server error');
    } catch(e){
        return Observable.throw(error || 'Server error');
    }
  }
}