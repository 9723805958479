<div id="bighome" class="wrapper wrapper_lg air air_horz">
    <div class="row">
        <div class="d-flex col-lg-8 l-white justify-content-center">                   
            <div class="ml-lg-10">
                <div class="flex-column"> 
                <br>  
                <p class="h1 text-left mt-3 ml-3">
                    TransForma Connect</p><br>
                    <p class="text-left ml-3 mr-3">
TransForma Connect is a platform of introduction. Select your specialties and create your profile. Dental professionals will be contacted directly by practices seeking their skills.</p><p>&nbsp;</p>

                    <div class="col-12-lg">                        
                        <p class="h2 text-center ml-3 mr-3">Connect with Dental Markets</p>
                        <p>&nbsp;</p>                        
                    </div>
                   
                    <div class="d-flex col-12-lg mb-4 justify-content-center">
                        <br>    
                         <button class="btn btn-primary ml-3 mt-3" routerLink="/officeform">&nbsp;Create Office Profile&nbsp;&nbsp;&nbsp;</button>
                         <button class="btn btn-outline-primary ml-3 mt-3" routerLink="/proform">Create Professional Profile</button>
                         <p>&nbsp;</p>
                   </div>
                   <div class="d-flex col-12-lg mt-4 justify-content-center">
                        <div>
                            <img src="assets/img/ui/prof1.jpg" width="auto" height="140" />
                            <img src="assets/img/ui/prof4.jpg" width="auto" height="140" />
                        </div>
                   </div>
              </div>
            </div>
        </div>
        <div class="flex-column col-lg-4 l-grey">
             <p>&nbsp;</p>
             <p>&nbsp;</p>
            <app-login-form></app-login-form>         
        <div>            
    </div>
</div>
<app-footer></app-footer>