<nav class="navigation-wrapper navbar navbar-expand-lg navbar-dark fixed-top flex-column autoHideNav bg-dark" id="mainNav" style="max-height:100px;">
    <div class="container" id="mainContainer">
        <img class="mb-4" src="assets/img/logos/logo.png" alt="Transforma" width="200" height="auto"><span *ngIf="unreadMsg>0" class="badge badge-pill badge-danger">{{unreadMsg}}</span>

        <button *ngIf="showNav" class="navbar-toggler navbar-toggler-right" (click)="menuIsCollapsed = !menuIsCollapsed" aria-controls="navbarResponsive"  [attr.aria-expanded]="!menuIsCollapsed" aria-label="Toggle Navigation" (click)="makeRoom()">Menu&nbsp;<i class="fa fa-bars"></i></button>
       
        <div class="collapse navbar-collapse" id="navbarResponsive" [ngbCollapse]="menuIsCollapsed">
            <ul class="navbar-nav text-uppercase ml-auto" >
               <li class="nav-item" *ngIf="user.FkType!=1 && user.isAdmin"><a class="nav-link js-scroll-trigger" href="javascript:void(0)" routerLink="/listoffices" (click)="menuIsCollapsed = true">OFFICES</a></li>
                <li class="nav-item" *ngIf="user.FkType!=1"><a class="nav-link js-scroll-trigger" href="javascript:void(0)" routerLink="/listprof" (click)="menuIsCollapsed = true">PROFESSIONALS</a></li>            
                <li ngbDropdown class="nav-item d-inline-block">
                  <a class="nav-link" href="javascript:void(0)" id="navbarDropdown" ngbDropdownToggle>USER
                  </a>
                  <div ngbDropdownMenu aria-labelledby="navbarDropdown">
                    <a *ngIf="user.FkType==2" class="dropdown-item" href="javascript:void(0)" routerLink="/messagelist/list" (click)="menuIsCollapsed = true">MY LISTS
                    </a>
                    <a class="dropdown-item" href="javascript:void(0)" routerLink="/messagelist" (click)="menuIsCollapsed = true">MY MESSAGES&nbsp;&nbsp;<span class="badge badge-pill badge-danger" *ngIf="unreadMsg>0">{{unreadMsg}}</span>
                    </a>
                    <a *ngIf="user.FkType==1" class="dropdown-item" href="javascript:void(0)" (click)="menuIsCollapsed = true" routerLink="/proform/{{user.PkUser}}">MY PROFILE</a>
                    <a *ngIf="user.FkType>=2" class="dropdown-item" href="javascript:void(0)" (click)="menuIsCollapsed = true" routerLink="/officeform/{{user.PkUser}}">MY PROFILE</a>
                    <!-- <a *ngIf="user.FkType>=2" class="dropdown-item" href="javascript:void(0)" routerLink="/mylist/{{user.PkUser}}" (click)="menuIsCollapsed = true">MY LISTS</a> -->
                    <a class="dropdown-item" href="javascript:void(0)" (click)="logout()" (click)="menuIsCollapsed = true">LOGOUT</a>
                  </div>
                </li>

            </ul>
        </div>
    </div>
</nav>