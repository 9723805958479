import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User,Office,Specialty,Institution } from '../model/dto';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-office-form',
  templateUrl: './office-form.component.html',
  styleUrls: ['./office-form.component.css']
})
export class OfficeFormComponent implements OnInit {

	private fields:string[] = ['Name', 'Years', 'Contact', 'Telephone',	'Email', 'Address', 'City', 'State', 'Zip', 'Email', 'email',
								 'password'];
   subscription: any;
   basesubs:any;
   @ViewChild('unsubscribe') unsubs: ViewChild;
   public user:User = new User(0,0,'','','','','');
   public office:Office = Office.getInstance();
   public file:File;
   public generalError:string;
   public years:number[] = [];
   public thisIsEdit:boolean = false;
   private password:string;
   private isEdit:boolean = false;
   public submitmsg:string = 'Create Office Profile';
   public isAdmin:boolean = false;
   public PkOrganization:number = 0;
   public institutions:Institution[];
   public reason:string;
   public antiguedad:number = 0;
   public refund:boolean = false;
  constructor(private _api:ApiService, private route:ActivatedRoute, 
    private router:Router, private _baseModel:BaseModel, private _userModel:UserModel) { }

  ngOnInit() {
      this.subscription = this.route.params.subscribe(params => {
         var id:number = parseInt(params['id']);
         if(!isNaN(id))
           this._api.getOfficeByUser(id).subscribe(result => this.onOffice(result));
       });
    this.basesubs = this._baseModel.docItem$.subscribe(item => this.changed(item));
    this.isAdmin =(this._userModel.user)?(this._userModel.user.FkType==4):false;
    if(this.isAdmin){
        this._api.getInstitutions()
          .subscribe(result=>this.onInstitution(result))
    }      
    var today:Date = new Date();
    for(var i:number = today.getFullYear(); i>=1980; i--) this.years.push(i)
    window['initSearch'](); //google maps help
  }
  
  onInstitution(data:object){
    this.institutions = data as Institution[];
  }

   ngAfterViewInit(){
     setTimeout(function(w){
       w.hideErrors();
     },1000, this)
  }

  changed(item:Object){
    //console.log("Evt "+item['name']);//+":"+item['value'])
    //for(var prop in item['value']) console.log(prop+": "+item['value'][prop])
    let key:string = item["name"];
    let value:any = item["value"];
    if(key=="backerror") {
      this.generalError = value;
    }
  }

  onOffice(data:object){
    this.thisIsEdit = true;
    this.office = data as Office;
    this.office.Image = this._api.domain+'images/offices/'+this.office.Image;
    this.user = this.office.user;
    this.user.password = '********';
    var a:Date = new Date();
    var b:Date = this._api.convertDBtoDate(this.office.Date);
    this.antiguedad = this._api.dateDiffInDays(a, b); //for refund in unsubscribe
    this.submitmsg = 'Update Office Profile';
  }
  
  save(){
    this.hideErrors();
    var error:boolean = false;
    //user
    for(var i:number = 0; i<10; i++) if(!this.office[this.fields[i]] || this.office[this.fields[i]].length<1){
      error = true;
      $('#'+this.fields[i]+'_error').show();
    }
    //user
    if(this.user.email.length<3) {error = true;  $('#email_error').show()}
    if(!this.user.password || this.user.password.length<8) {error = true;  $('#password_error').show()}
    this.user.FkType = 2;
    this.password = this.user.password;
    this._userModel.tmpPass = this.user.password;
    if(!error) 
      this._api.createUser(this.user).subscribe(result => this.onSaveUser(result));
  }

   onSaveUser(data:object){
    if(data['success']==true || data['status']=='success'){
      this.office.FkUser = data['data']['PkUser'];
       this.user = data['data'] as User;
      this._api.createOffice(this.office, this.file, this.PkOrganization)
        .subscribe(result =>(this.thisIsEdit)?this.onFinish(result):this.onLogin(result));
    } else this.generalError = "The user couldn't be created"
  }

  onLogin(data:object){
    this._api.getToken(this.user.email, this.password)
      .subscribe(result=>this.onToken(result))
  }

 onToken(data:object){
    var token:string = data["token"];
    if(!token) {      
      this.generalError = "User or password doesn't match";
    }  else { //tengo el token, me logueo
       localStorage.setItem('access_token', token);
       this._userModel.user = this.user;
       //When payment enables again, uncoment the next lines
       this._api.loginUser(this.user.email, this.password, true)
        //.subscribe(result=>this.onPayment(result))
        .subscribe(result=>this.onFinish(result))
    }
  }

  onAddress(){    
    setTimeout(this.captureAddress, 1000, this);    
  }

   captureAddress(w){
    //315 Madison Avenue, New York, NY, USA   
    //need to capture from control, biodibg doesnt catch content 
     var addr = document.getElementById('Address')['value'];     
     var address = addr.split(',');          
     if(address.length>1){
       w.office.Address = address[0].trim();
       w.office.City = address[1].trim();
       w.office.State = address[2].trim();
       //copy the values, binding was not fired
       w.office.Zip = document.getElementById('zip')['value'];
       w.office.Latitude = document.getElementById('Latitude')['value'];
       w.office.Longitude = document.getElementById('Longitude')['value'];
    }       
  }

  onFinish(data:object){
     //if(data['PkUser']) {
        this.router.navigate(['/listprof'])
     //} else this.generalError = "The office couldn't be created"
  }

  onPayment(data:object){
   // console.log(data)
    if(data['PkUser']) {
        this.router.navigate(['/payment/'+data['PkUser']])
    } else this.generalError = "The office couldn't be created"
  }

  unsubscribe(){    
    window['closeModal']('unsubscribe', 'hide');
    $("body").removeClass("modal-open");
    $('.modal-backdrop').remove();
    this._api.cancelSubscription(this.user.PkUser)
        .subscribe(result=>this.onCanceled(result))
  }

  onCanceled(data:object){
    console.log(data);
      this._api.unsubscribeoffice(this.user.PkUser, this.reason)
        .subscribe(result=>this.onLogout(result))
  }

  onLogout(data:object){
    window.localStorage.clear()
    this.router.navigate(['/'])
  }

  loadFile(evt:any){
    //preview
    if (evt.target.files && evt.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        $('#Image').attr('src', e.target['result']);
      }

      reader.readAsDataURL(evt.target.files[0]);
    }
    //store
    let fileList: FileList = evt.target.files;
    if (fileList.length == 0) {
      console.log("No file selected!");
      return
    }
    let file: File = fileList[0];
    this.file = file; 
  }

  hideErrors(){
  	for (var i = 0; i < this.fields.length; i++) $('#'+this.fields[i]+'_error').hide();
    $('#email_error').hide();
  	//this.generalError = '';
  }
}
