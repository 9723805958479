  <div class="container-fluid d-none d-lg-block mt-2 mb-1">
      <div class="row bg-white">
          <div class="col-1 col-xl-2">&nbsp;</div>
          <div class="col-3 col-xl-2">
            <p class="h4 ml-3 mt-3 mb-3">{{title}}</p>
          </div> 
          <div class="col-4">
            <div class="float-middle">
              <form class="flex-row form-inline my-2 my-lg-0">
                    <input class="form-control my-2" type="text" name="kw" placeholder="Search" [(ngModel)]="keyword">
                    <button class="btn btn-secondary my-2 my-sm-0" (click)="gralSearch()">Search</button>
                
                &nbsp;&nbsp;<span class="errormsg">{{gralerror}}</span>
                <div *ngIf="mode==3">
                  <select name="listFilter" id="listFilter" [(ngModel)]="selList" (change)="filterList()">
                    <option *ngFor="let item of adminLists" value="{{item.value}}">{{item.label}}</option>
                  </select>
                </div>
                </form>
              </div>
          </div>
          <div class="col-3 col-xl-2 right">
            <div class="float-right">
              <button *ngIf="mode==1" type="button" class="btn btn-outline-secondary my-2 text-warning" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
              Advanced Search
              </button>
              <!-- create list button -->
              <button *ngIf="mode==3" type="button" class="btn btn-outline-secondary my-2 l-grey" (click)="createList()">
              Create list
              </button>       
            </div>
          </div>
          <div class="col-1 col-xl-2">&nbsp;</div>
        </div>
      </div>
      <!-- Advance Search container for Profesional -->
      <div class="container d-none d-lg-block bg-light">
        <div class="row" id="collapseExample" [ngbCollapse]="isCollapsed">
          <div class="col-2" *ngIf="user.FkType<2">
            <div class="form-group mt-2">
              <input class="form-check-input ml-1" type="checkbox" id="location" value="1" [(ngModel)]="location">
              <label class="form-check-label ml-4" for="location">Location</label>
              <input type="text" class="form-control" id="city" placeholder="Oviedo" [(ngModel)]="locationKey">
            </div>
          </div>
          <div class="col-2" *ngIf="user.FkType>=2">
            <div class="form-group mt-2">
              <input class="form-check-input ml-1" type="checkbox" id="distance" value="1" [(ngModel)]="distance">
              <label class="form-check-label ml-4" for="location">Distance (miles)</label>
              <input type="number" class="form-control" id="distanceKey" [(ngModel)]="distanceKey">
            </div>
          </div>
           <div class="col-2">
            <div class="form-group mt-2">
              <input class="form-check-input ml-1" type="checkbox" id="specialtyChk" value="1"  [(ngModel)]="specialty">
              <label class="form-check-label ml-4" for="specialtyChk">Specialty</label>
              <select class="form-control" id="specialty" name="specialty" [(ngModel)]="specialtyKey">
                 <option *ngFor="let item of specialties" value="{{item.PkSpecialty}}">{{item.Name}}</option>
              </select>
            </div>
          </div>
           <div class="col-lg-2">
             <div class="form-group mt-2">
                <input class="form-check-input ml-1" type="checkbox" id="rateChk" value="1" [(ngModel)]="rate">
                <label class="form-check-label ml-4" for="rateChk">Max. hourly rate</label>
                  <div class="input-group mb-2 mr-sm-2">
                    <div class="input-group-prepend">
                      <div class="input-group-text">$</div>
                    </div>
                    <input class="form-control" type="number" name="rate" step="1" value="5"  [(ngModel)]="rateKey">
                  </div>
              </div>
           </div>
           <div class="col-2">
            <div class="form-group mt-2">
               <input class="form-check-input ml-1" type="checkbox" id="yearsChk" value="1"  [(ngModel)]="experience">
                <label class="form-check-label ml-4" for="yearsChk">Min. experience</label>
                <div class="input-group mb-2 mr-sm-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Years</div>
                  </div>
                  <input class="form-control" type="number" name="years" step="1" value="5"  [(ngModel)]="experienceKey">
                </div>
            </div>
          </div>
           <div class="col-3">
            <div class="form-group mt-2">
                 <br><button class="btn btn-secondary my-2 my-sm-0" (click)="filter()">Filter</button><br><span class="errormsg">{{error}}</span>
              </div>
          </div>
        </div>
      </div>
      <!-- END advance Search container for Profesional -->
       <!-- only mobile -->
        <div class="container mb-1 l-white">
                <form class="row d-lg-none" *ngIf="isMAsearchCollapsed">
                   <p class="h4 mt-3 ml-4">{{title}}</p>
                   <div class="input-group mb-3 ml-4 mr-4">
                     <input type="text" class="form-control" placeholder="search" aria-label="search" aria-describedby="basic-search" name="search" id="search" [(ngModel)]="keyword">
                     <div class="input-group-append">
                        <button class="btn btn-outline-secondary" type="button" (click)="gralSearch()">Search</button>
                        <br><small class="errormsg">{{gralerror}}</small>
                     </div>
                    </div>
                    <div class="container l-grey">
                      <div class="row mb-2 mt-0">                
                        <div class="d-flex col-12 justify-content-center">                   
                            <span *ngIf="mode==1">&nbsp;&nbsp;<a class="nav-link js-scroll-trigger yellowg h5" (click)="isMAsearchCollapsed = !isMAsearchCollapsed" [attr.aria-expanded]="!isMAsearchCollapsed" aria-controls="collapseMAsearch" href="javascript:void(0)"><u>Advanced Search</u></a></span>                    
                        </div>
                      </div>
                    </div>
                </form>
                <ul class="nav-item d-lg-none l-white">                 
                  <div class="flex-column" id="collapseMAsearch" [ngbCollapse]="isMAsearchCollapsed">
                      <p>&nbsp;</p>
                       <div class="form-group" *ngIf="user.FkType<2">
                         <div class="form-inline form-group mt-2">
                          <input type="text" class="form-control" id="city" placeholder="Location"  [(ngModel)]="locationKey">
                        </div>
                      </div>
                       <div class="form-group" *ngIf="user.FkType>=2">
                         <div class="form-inline form-group mt-2">
                          <input type="number" class="form-control" id="distance" placeholder="Maximum distance (miles)"  [(ngModel)]="distanceKey">
                        </div>
                      </div>
                      <div class="form-group mt-2">
                        <div class="row">
                          <div class="col-12">
                              <div class="form-inline form-group mt-2">
                                <select class="form-control" id="specialty" name="specialty" [(ngModel)]="specialtyKey">
                                   <option value="0">Specialty</option>
                                   <option *ngFor="let item of specialties" value="{{item.PkSpecialty}}">{{item.Name}}</option>
                                </select>
                                <hr>
                               <div class="input-group mt-3">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Max. hourly rate $</div>
                                  </div>
                                    <input class="form-control" type="number" name="rate" step="1" value="5"  [(ngModel)]="rateKey">
                                  </div>
                                </div>

                                 <div class="input-group mt-3">
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">Min. years of experience</div>
                                  </div>
                                    <input class="form-control" type="number" name="years" step="1" value="1"  [(ngModel)]="experienceKey">
                                  </div>
                            </div>
                             <button class="btn btn-lg btn-outline-secondary mt-3 mb-3 ml-3" (click)="filterMobile()">Search {{title}}</button>
                              <small class="errormsg">&nbsp;&nbsp;{{error}}</small><br>
                              <small class="h6 ml-3"><a class="nav-link js-scroll-trigger" (click)="isMAsearchCollapsed = !isMAsearchCollapsed" [attr.aria-expanded]="!isMAsearchCollapsed" aria-controls="collapseMAsearch" href="javascript:void(0)">Back to Simple Search</a></small>
                               <p>&nbsp;</p>
                          </div>
                      </div>
                  </div>
                </ul>
                <!-- end only mobile -->
            </div>