import { Component, OnInit, Input, EventEmitter  } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Proffesional,User } from '../model/dto';
import { Router } from '@angular/router';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.css'],
  inputs: ['mode']
})
export class SearchBoxComponent implements OnInit {
   subscription:any;
  public action = new EventEmitter();
  public isCollapsed:boolean = true;
  public isMAsearchCollapsed:boolean = true;
  public mode:number; //1->Profesional 2->Office
  public titles:string[] = ['Professionals', 'Offices', 'My Lists'];
  public title:string;
  public specialties: any[];
  public location:boolean = false;
  public locationKey:string = '';
  public specialty:boolean = false;
  public specialtyKey:number = 0;
  public distance:boolean = false;
  public distanceKey:number;
  public rate:boolean = false;
  public rateKey:number;
  public experience:boolean = false;
  public experienceKey:number;
  public error:string = '';
  public keyword:string = ' ';
  public gralerror:string;
  public user:User;
  public isAdmin:boolean = false;
  public adminLists:object[];  
  public selList:number = 0;
  public coords:number[] = [];
  constructor(private _api:ApiService, private _baseModel:BaseModel, private _userModel:UserModel) {
    this.subscription = this._baseModel.docItem$.subscribe(
        item => this.changed(item)
    );    
  }

  changed(item:Object){
    //console.log("Evt "+item['name']);//+":"+item['value'])
    //for(var prop in item['value']) console.log(prop+": "+item['value'][prop])
    let key:string = item["name"];
    let value:any = item["value"];
    if(key=="lista") {
      this.adminLists.push(value)    
      this.adminLists.sort((obj1, obj2) => {
        if (obj1['Name'] > obj2['Name']) {
            return 1;
        }

        if (obj1['Name'] < obj2['Name']) {
            return -1;
        }

        return 0;
    });
    }
  }

  ngOnInit() {
  	this.title = this.titles[this.mode-1];
    this.user = this._userModel.user
    if(this.user.FkType>=2){
      //use distance
      this._api.getSender(this.user.PkUser)
        .subscribe(result=>this.onOffice(result))     
    }
    this.isAdmin = this.user.isAdmin;
    this._api.getSpecialties()
      .subscribe(result => this.onSpecialties(result));     
    if(this.user.FkType>1){
      this._api.getLists(this.user.PkUser)
        .subscribe(result=>this.onLists(result))
    }    
  }

  onOffice(data:object){
    //console.log(data);
    var lat:number = parseFloat(data['office']['Latitude']);
     var long:number = parseFloat(data['office']['Longitude']);
     this.coords = [long, lat];
  }

  onLists(data:object){
    this.adminLists = []
    var tot = data['length']
    var cant:number = 0;
    for (var i = 0; i < tot; i++) {
     cant = data[i]['members']['length']
     this.adminLists.push({label:data[i].Name+'('+cant+')', value:data[i].PkList});
     if(data[i].Name=="My List") this.selList = data[i].PkList;
    }
    this.filterList();
  }

  filterList(){
    this._baseModel.setProp({name:'filterList',value:this.selList})    
  } 

  createList(){    
    this._baseModel.setProp({name:'createList', value:1})
  }

  onSpecialties(data:object){
     this.specialties = data as object[];

  }

  filter(){
    //check that item has value
    var filters:object = {}
    this.error = '';
    if(this.distance){
      if(this.distanceKey < 1) this.error = 'Distance should be greather than zero'
       else filters['distance'] = this.distanceKey;
    }
    if(this.location){
      if(this.locationKey.length<3) this.error = 'Location should have at least 3 letters'
       else filters['location'] = this.locationKey;
    }
    if(this.specialty) {
      if(this.specialtyKey<1) this.error = 'Should select a specialty';
      else filters['specialty'] = this.specialtyKey;
    }
    if(this.rate) {
      if(!this.rateKey) this.error = 'Should enter a rate';
      else filters['rate'] = this.rateKey;
    } 
    if(this.experience) {
      if(!this.experienceKey || this.experienceKey<1) this.error = 'Should enter experience'
      else filters['experience'] = this.experienceKey;
    }
    filters['coords'] = this.coords;
    if(this.error!='') return this.error;
    this._baseModel.setProp({name:'filterProf', value:filters})
  }

  filterMobile(){
    var filters:object = {};
    var count:number = 0;
    this.error = '';
    if(this.locationKey && this.locationKey.length) filters['location'] = this.locationKey;
    if(this.distanceKey && this.distanceKey>0) filters['distance'] = this.distanceKey; 
    if(this.specialtyKey && this.specialtyKey>0) filters['specialty'] = this.specialtyKey;    
    if(this.rateKey &&  this.rateKey>0) filters['rate'] = this.rateKey;
    if(this.experienceKey && this.experienceKey>0) filters['experience'] = this.experienceKey;
    filters['coords'] = this.coords;
    for(var prop in filters) count++;
    if(count>0) this._baseModel.setProp({name:'filterProf', value:filters})
     else this.error = 'No input to search';
  }

  gralSearch(){
    this.gralerror = '';
    //console.log("Keyword: "+this.keyword)
    if(this.keyword==' ' || this.keyword.length<3) return this.gralerror ='Keyword should have at least 3 characters'
    if(this.mode==1) this._baseModel.setProp({name:'searchProf', value:this.keyword.trim()})
    if(this.mode==2) this._baseModel.setProp({name:'searchOffice', value:this.keyword.trim()})
    if(this.mode==3) this._baseModel.setProp({name:'searchList', value:this.keyword.trim()})
  }
 
}
