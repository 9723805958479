
import { Component, Injectable, Output} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../model/dto';
import { BaseModel } from '../model/base.model';

export class UserModel extends BaseModel{
	//public tiendas: Tienda[] = [];
	public _user:User;
	public userId:number;
	private _tmpPass:string; //save and deleted when retrieved automatically
	
	set user(currentUser:User){
		this._user = currentUser;
		this.userId = currentUser.PkUser;
		//store in session storage
		//var label:string =  this.getTypeLabel(currentUser.FkRol) as string;
		var storeUser = new User(currentUser.PkUser, currentUser.FkType, currentUser.Name, currentUser.LastName,
		 currentUser.email, currentUser.created_at, currentUser.password, currentUser.isAdmin);
		localStorage.setItem('user', JSON.stringify(storeUser));
	}
	
	get user():User{
		if(this._user) {
			return this._user
		} else {
			var value = localStorage.getItem('user');
    		return value && JSON.parse(value);
		}
	}

	set tmpPass(val:string){
		this._tmpPass = val;
		localStorage.setItem('tmpPass', JSON.stringify(val));
	}

	get tmpPass():string{
		if(this._tmpPass) {
			return this._tmpPass
		} else {
			var value = localStorage.getItem('tmpPass');
			localStorage.setItem('tmpPass', null); //delete inmediatly
    		return value && JSON.parse(value);
		}
	}
	public clear(){
		this._user = null;
		localStorage.clear();
	}
}
