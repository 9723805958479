import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User,Proffesional, Specialty, Language } from '../model/dto';
import { Router, ActivatedRoute } from '@angular/router';
import { FullImageComponent } from '../full-image/full-image.component';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';
import {NgbModal, NgbModalRef, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-pro-detail',
  templateUrl: './pro-detail.component.html',
  styleUrls: ['./pro-detail.component.css'],
  inputs: ['action']
})
export class ProDetailComponent implements OnInit {

subscription:any;
subscription2:any;
public proffesional:Proffesional = Proffesional.getInstance();
public years:number = 0;
public specialties:Specialty[];
public specialtyNames:string = '';
public selSpecialties:Specialty[] = [];
public user:User;
public status:string;
public hideProfile:boolean = false;
public isMobile:boolean = false;
public daysSelected:string[] = [];
public canEdit:boolean = false;
public lang:string;
protected languages:Language[];
public auxModal:NgbModalRef;
public profImage:string='';
private id:number;

@ViewChild('fullImage') fullImage: ViewChild;

 constructor(private _api:ApiService, private route:ActivatedRoute, private modalService: NgbModal,
    private router:Router, private _baseModel:BaseModel, private _userModel:UserModel) { 
   this.user = this._userModel.user;
 	 this._api.getSpecialties()
      .subscribe(result => this.onSpecialties(result)); 
 }
  
  ngOnInit() {
    //this.isMobile = ($(window).width()<600)
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
  || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) this.isMobile = true;

  	this.subscription = this.route.params.subscribe(params => {
         this.id = parseInt(params['id']);        
       }); 
    this.subscription2 = this._baseModel.docItem$.subscribe(
        item => this.changed(item)
    );  
  }

  changed(item:Object){
    //console.log("Evt "+item['name']+":"+item['value'])
    //for(var prop in item['value']) console.log(prop+": "+item['value'][prop])
    let key:string = item["name"];
    let value:any = item["value"];
   
    if(key=='closeWindow') this.auxModal.close(); 
  }

  onSpecialties(data:object){
     this.specialties = data as Specialty[];
     this._api.getLanguages()
      .subscribe(result => this.onLanguages(result)); 
  }

  onLanguages(data:object){
    this.languages = data as Language[];
     if(!isNaN(this.id))
        this._api.getProfessional(this.id)
         .subscribe(result => this.onProffesional(result));
  }

  onProffesional(data:object){
    console.log(data);
   	var today = new Date();
   	var spec:Specialty;
    this.proffesional = data[0] as Proffesional;
    //Admin can edit and DSGO can edit his members
    var isDSGO = (this.user.PkUser == 73)
    var isAdmin = (this.user.email=='admin@admin.com')
    this.canEdit = (isAdmin || (isDSGO && this.proffesional.FkInstitution==1))

    if(data[0]['deleted_at']) this.hideProfile = true;
    if(this.proffesional['Days']){
      var days = this.proffesional['Days'].split(',')
      var d:string;
      for (var i = 0; i < days.length; i++) {
        if(days[i]=='m') d = ' Mon';
        if(days[i]=='t') d = ' Tue';
        if(days[i]=='w') d = ' Wed';
        if(days[i]=='th') d = ' Thu';
        if(days[i]=='f') d = ' Fri';
        if(days[i]=='s') d = ' Sat';
        this.daysSelected.push(d)
      }  
    }
    
    for (var i = 0; i < this.proffesional.specialty.length; i++) {
    	spec = this.proffesional.specialty[i];
    	spec.PkSpecialty = this.proffesional.specialty[i]['FkSpecialty'];
    	if(this.specialties) spec.Name = this.specialties.filter(item => item['PkSpecialty'] ==spec.PkSpecialty)[0]['Name'];
   		this.selSpecialties.push(spec)
    }
    this.lang = ''; var sel:Language;
    for (i = 0; i < this.proffesional.language.length; i++) {
      sel = this.languages.filter(item => item['PkLanguage'] == this.proffesional.language[i]['FkLanguage'])[0];
      this.lang +=sel.Name+', '
    }
    this.lang = this.lang.substr(0, this.lang.length-2);
    this.years = today.getFullYear() - this.proffesional.Experience
    if(this.proffesional.Image && this.proffesional.Image.length>4)
    this._api.fileExists(this._api.domain+'images/proffesionals/'+this.proffesional.Image)
    	.subscribe(result=>this.showImage(result)); 
    if(this.proffesional.Pdf && this.proffesional.Pdf.length>4)
   	 this._api.fileExists(this._api.domain+'images/cvs/'+this.proffesional.Pdf)
    	.subscribe(result=>this.showPdf(result));    
  }


  showFullImage(){
    var tmp = this.proffesional.Image.split('/');
    var img = tmp[tmp.length-1]
    if(this.isMobile) this.router.navigate(['/fullimage/'+this.proffesional.FkUser+'-'+img])
    else {
      this.auxModal = this.modalService.open(FullImageComponent);
      this.auxModal.componentInstance.image = this._api.domain+'images/proffesionals/'+img;
      this.auxModal.componentInstance.isMe = ((this.user.PkUser == this.proffesional.FkUser) || this.user.isAdmin)
    }
  }

  showImage(data){
  	if(data!=0) this.profImage = this._api.domain+'images/proffesionals/'+this.proffesional.Image+"?"+Math.random();
  	else  this.profImage = this._api.domain+'images/proffesionals/placeholder.png';   
  }
  showPdf(data){
  	if(data!=0) this.proffesional.Pdf = this._api.domain+'images/cvs/'+this.proffesional.Pdf
  	else  this.proffesional.Pdf = null;
  }

  handleAction(evt){
    
    if(evt.name=='message'){
      var tmp = evt.value.split('|')      
      this._api.sendMessage(this.user.PkUser, this.proffesional.user.PkUser, tmp[0], tmp[1], tmp[2])
        .subscribe(result=>this.onMessage(result));
    }
    if(evt.name=='saveToMyList'){
        this._api.addToMyList( this.proffesional.user.PkUser, this.user.PkUser)
          .subscribe(result=>this.onList(result));
    }
  }

  onMessage(data:object){
    this.status = "Message sent to "+this.proffesional.FirstName+" "+this.proffesional.LastName;
     window['closeModal']('msg', 'show');
  }
  onList(data:object){
    if(data['success']==true){
      this.status = this.proffesional.FirstName+" "+this.proffesional.LastName+" was added to your list";
    }  else this.status = 'This proffesional is already in your list'
    window['closeModal']('confirma', 'show');
  }
}
