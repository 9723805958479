import { Injectable, Component, ReflectiveInjector, OnInit } from '@angular/core';
import { HttpModule, Http, Response, XHRBackend,ConnectionBackend,BrowserXhr,
        ResponseOptions, XSRFStrategy, BaseResponseOptions, CookieXSRFStrategy,
        RequestOptions, BaseRequestOptions,Headers, RequestOptionsArgs } from '@angular/http';
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';
import { map, filter, scan, catchError, mergeMap, finalize } from 'rxjs/operators';
import { BaseModel } from '../model/base.model';
import { HttpHeaders } from '@angular/common/http';

export class HttpClient {
  private loadQueue:number = 0;
  protected jwt: string;
  protected decodedJwt: string;
  protected http:Http;
  
  constructor() {

        let injector = ReflectiveInjector.resolveAndCreate([Http,HttpClient,BrowserXhr,BaseModel,
            { provide: ConnectionBackend, useClass: XHRBackend },
            { provide: ResponseOptions, useClass: BaseResponseOptions },
            { provide: XSRFStrategy, useValue: new CookieXSRFStrategy('csrftoken', 'X-CSRFToken')},
            { provide: RequestOptions, useClass: BaseRequestOptions }]);

         this.http = injector.get(Http);

        this.jwt = localStorage.getItem('access_token');
        //this._httpClient.authHttp = this.authHttp;
        //Necesito darle algun token sino lanza una excepcion al instanciarse
        this.jwt = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0LzM4MTgtMDMyLWZsdXgtY29tbWVyY2UtMjAxNy9kZXZlbG9wbWVudC9iYWNrZW5kL3B1YmxpYy9sb2dpbiIsImlhdCI6MTUwMjQ4NzAzOSwiZXhwIjoxNTAyNDkwNjM5LCJuYmYiOjE1MDI0ODcwMzksImp0aSI6IklCRkQ2M0x5ejZwT3ZsVTciLCJzdWIiOjEsInBydiI6Ijg3ZTBhZjFlZjlmZDE1ODEyZmRlYzk3MTUzYTE0ZTBiMDQ3NTQ2YWEifQ.xsCGgokUikk_dZBt13sZRKPgmxmcbqYvXRxkWAEk1oU';
       // this.decodedJwt = this.jwt && window["jwt_decode"](this.jwt); 

  }

  //set authHttp(gtw:AuthHttp){ this._authHttp = gtw }
  //get authHttp():AuthHttp{ return this._authHttp }
  
  get(url:string) {
    //console.log('get '+url);
    url = this.addAntiCache(url);
    let options = this.getHeaders();
    if(this.loadQueue==0) this.startLoading();
    var pending:Observable<any> = this.http.get(url, options).pipe(finalize(() => this.finish()));
    return pending;
  }

  startLoading(){
    window["launchLoader"]();
    this.loadQueue++;
    setTimeout(function(w){ w.finish() }, 30000, this); //30 sgs
  }
  
  finish(){
    this.loadQueue--;
    //console.log("Loadqueue "+this.loadQueue);
    if(this.loadQueue<=0){
      //this._baseModel.setProp({name:"termina", value:1})
      window["stopLoader"]();
      this.loadQueue = 0;
    } 
  }

  post(url:string, data:string) {
    //console.log('post '+url);
    url = this.addAntiCache(url);
    let options = this.getHeaders();
    //let options:RequestOptionsArgs = new RequestOptions({ headers: headers });
    if(this.loadQueue==0) this.startLoading();
    var pending:Observable<any> = this.http.post(url, data, options).pipe(finalize(() => this.finish()));
    //this.createAuthorizationHeader(headers);
    return pending;
  }

  put(url:string, data:string) {
    //console.log('put '+url);
    url = this.addAntiCache(url);
    let options = this.getHeaders();
    if(this.loadQueue==0) this.startLoading();   
    var pending:Observable<any> = this.http.put(url, data, options).pipe(finalize(() => this.finish())); 
    //this.createAuthorizationHeader(headers);
    return pending;
  }

  delete(url:string) {
    //console.log('put '+url);
    url = this.addAntiCache(url);
    let options = this.getHeaders();
    if(this.loadQueue==0) this.startLoading();   
    var pending:Observable<any> = this.http.delete(url, options).pipe(finalize(() => this.finish())); 
    //this.createAuthorizationHeader(headers);
    return pending;
  }

   postFile(url, data) {
    if(this.loadQueue<=0) this.startLoading();
    let options = this.getMultipartHeaders();
    var pending:Observable<any> = this.http.post(url, data, options).pipe(finalize(() => this.finish()));
    //this.createAuthorizationHeader(headers);
    return pending;
  }

  putFile(url, data) {
    if(this.loadQueue<=0) this.startLoading();
    //zconsole.log('LoadQueue '+this.loadQueue);
    let options = this.getMultipartHeaders();
    if(this.loadQueue==0) this.startLoading();
    var pending:Observable<any> = this.http.put(url, data, options).pipe(finalize(() => this.finish()));
    //this.createAuthorizationHeader(headers);
    return pending;
  }

  getHeaders():object{
     let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', 'Bearer '+localStorage.getItem('access_token'))
    let options = { headers: headers };
    return options
  }
  getMultipartHeaders():object{
    let headers = new Headers();
    //headers.append("Content-Type", "multipart/form-data");
    headers.append('Authorization', 'Bearer '+localStorage.getItem('access_token'))
    let options = { headers: headers };
    return options;
  }
  //agrega random a la url para evitar el cache
  addAntiCache(url:string):string{
    //var newUri = (url.indexOf("?")!=-1)?url+'&cache='+Math.random():url+'?'+Math.random();
    //return newUri;
    return url;
  }
}