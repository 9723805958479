import { Component, OnInit } from '@angular/core';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';
import { User } from '../model/dto';
import { ApiService} from '../services/api.service';
import {Router} from '@angular/router';
import { FormsModule } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.css']
})
export class LoginMobileComponent implements OnInit {
    public email:string = '';
    public password:string = '';
    public loginerror:string = ''
    public year:number;

    constructor(
          private _baseModel:BaseModel, 
          private userModel:UserModel, 
          private _api:ApiService, 
          private router:Router) {
      //If we're in mobile use a different login page
      //if($(window).width()<600) router.navigate(['/loginmobile']);
      var date = new Date();
      this.year = date.getFullYear();    
    }

  ngOnInit() {}

  public loginUser(){
    if(this.email.length<3) return this.loginerror = 'Email is mandatory';
    if(this.password.length<3) return this.loginerror = 'Password is mandatory';
    this._api.getToken(this.email, this.password)
      .subscribe(result=>this.onToken(result))
  }

  onToken(data:object){
    var token:string = data["token"];

    if(!token) {
      this.loginerror = "User or password doesn't match";
    }  else { //tengo el token, me logueo
       localStorage.setItem('access_token', token);
       this._api.loginUser(this.email, this.password)
        .subscribe(result=>this.onLogin(result))
    }
  }

  private onLogin(data:object){
    //console.log(data);
    this.userModel.user = data as User;
    this._baseModel.setProp({name:'login', value:1})
    this.router.navigate(['listprof']);
  }
}
