<app-navigation></app-navigation> 
 
   <app-search-box mode="1"></app-search-box>

   <div class="container">
    <table class="table table-bordered table-hover bg-white" id="profListContainer">
       <!-- standard page table -->
      <thead class="thead-light" *ngIf="!isMobile">
        <tr>
            <th scope="col"></th>
            <th scope="col">Name</th>
            <th scope="col">City</th>
            <th scope="col">State</th>
            <th scope="col">Specialty</th>
            <th scope="col">Hourly</th>
            <th scope="col">Since</th>
            <th scope="col"><input type="checkbox" name="selectall" id="selectall" (click)="checkAll()"></th>
        </tr>
      </thead>
       <!-- mobile page table -->
       <thead class="thead-light" *ngIf="isMobile"> 
        <tr>             
            <th width="30%" scope="col">Name</th>
            <th width="20%" scope="col">City</th>
            <th width="35%" scope="col">Specialty / Hourly Rate</th>
            <th width="10%" scope="col"><input type="checkbox" name="selectall" id="selectall" (click)="checkAll()"></th>
        </tr>
      </thead>
      <!-- standard page table -->
      <tbody *ngIf="proffesionals && !isMobile">
        <tr *ngFor="let item of proffesionals">
            <th scope="col"><img src="{{item.Image}}" class="listImg"></th>
            <th scope="row"><a href="javascript:void" routerLink="/prodetail/{{item.PkProffesional}}">{{item.FirstName}} {{item.Middlename}} {{item.LastName}}</a></th>
            <td>{{item.City}}</td>
            <td>{{item.State}}</td>
            <td>{{item.SpecialtyName}}</td>
            <td>${{item.HourlyRate}}</td>
            <td>{{item.Experience}}</td>
            <td><input type="checkbox" [name]="item.PkProffesional" [id]="item.PkProffesional"></td>
        </tr>
         <tr *ngIf="proffesionals.length<1"><td  colspan="8">No professionals found</td></tr>
      </tbody>
      <!-- Mobile table -->
      <tbody *ngIf="proffesionals && isMobile">
         <tr *ngFor="let item of proffesionals">
            <td width="25%"><img src="{{item.Image}}" class="listImg"><br>
              <a href="javascript:void" routerLink="/prodetail/{{item.PkProffesional}}">{{item.FirstName}} {{item.Middlename}} {{item.LastName}}</a></td>
            <td width="30%">{{item.FullAddress}}</td>
            <td width="40%">{{item.SpecialtyName}}<br>${{item.HourlyRate}}</td>
             <td width="5%"><input type="checkbox" [name]="item.PkProffesional" [id]="item.PkProffesional"></td>
        </tr>
         <tr *ngIf="proffesionals.length<1"><td  colspan="4">No professionals found</td></tr>
      </tbody>
    </table>
    <div class="d-flex row w-100 justify-content-end mb-2">
      <span class="h5 text-white" *ngIf="user.isAdmin">{{totalSearchResult}} entries</span>&nbsp;&nbsp;
      <button class="btn btn-outline-secondary text-white" (click)="addToList()">Add to list</button>
    </div>
  <nav aria-label="..." *ngIf="proffesionals && proffesionals.length>0 && showPagination" class="d-flex justify-content-center mt-2">
    <ngb-pagination [collectionSize]="total" [pageSize]="itemsPerPage" [(page)]="currentPage" [maxSize]="isMobile?5:10" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="loadPage($event)"></ngb-pagination>
  </nav>
  <hr>
 
    <nav aria-label="..." class="d-flex w-100 justify-content-center" *ngIf="!isMobile">
      <ul class="pagination pagination-sm">
        <li *ngFor="let item of letters"  [class]="(item==currentLetterPage)?'page-item disabled':'page-item'"><a href="javascript:void(0)" class="page-link" (click)="filterPage(item)">{{item}}</a></li>
      </ul>
    </nav>
 
  <br>
</div>
<app-footer></app-footer>

<ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Select list</h5>
        <button type="button" class="close" (click)="auxModal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      
            <label *ngIf="adminLists && adminLists.length>0" for="listname">Write the name or select from the list below</label><br>
            <label *ngIf="adminLists && adminLists.length<=0" for="listname">Write the list the name</label><br>
            <input class="form-control" type="text" name="sellist" id="sellist" [(ngModel)]="sellist"><br><br>
            <select *ngIf="adminLists && adminLists.length>0" class="form-control" name="listname" id="listname" [(ngModel)]="listname" (change)="listOption()">
              <option *ngFor="let item of adminLists" [value]="item.value">{{item.label}}</option>
            </select>
          
        <p><span text="text-center">{{profSel}} entries selected</span></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="auxModal.close()">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveToList()">Save</button>
      </div>
</ng-template>
