import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { User,Proffesional,Specialty,Language } from '../model/dto';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-pro-form',
  templateUrl: './pro-form.component.html',
  styleUrls: ['./pro-form.component.css']
})
export class ProFormComponent implements OnInit {
    subscription: any;
  
	private fields = ['FirstName', 'LastName', 'Mobile', 'Experience', 'HourlyRate', 'Address',
						'City', 'Zip', 'Experience', 'State', 'email', 'password']
  public user:User = new User(0,0,'','','','','');
  public proffesional:Proffesional = Proffesional.getInstance();
  public generalError:string;
  public specialties: Specialty[];
  public nonspecialties: Specialty[];
  public years:number[] = [];
  public assignedSpecialties:Specialty[] = [];
  public assignedNonSpecialties:Specialty[] = [];
  public languages:Language[] = [];
  public assignedLanguages:object[] = [];
  private file:File; //image
  private pdfFiles:FileList; //for upload
  public currentfiles:string[] = []; //for edit
  private thisIsEdit:boolean = false;
  private password:string;
  public action:string = 'Create'
  public specLabel:string = ''; //label for specialties on editing
  public reason:string ='';
  public hideProfile:boolean = false;
  private id:number;
  public isSameUser:boolean = false;
  public imgerror:string = '';
  //HARDCODED
  private admin:number = 52;
  private dsgo:number = 73
  public isAdmin:boolean = false;
  public isDSGO:boolean = false;
  public org:string;
  public window = window;

  constructor(private _api:ApiService, private route:ActivatedRoute, 
    private router:Router, private _baseModel:BaseModel, private _userModel:UserModel) {
        this._api.getSpecialties()
          .subscribe(result => this.onSpecialties(result)); 
        this._api.getLanguages()
          .subscribe(result => this.onLanguages(result)); 
     }

  ngOnInit() {
    var today:Date = new Date();
     for(var i:number = today.getFullYear(); i>=1980; i--) this.years.push(i)
        
    //isDsgo from querystring if new
    this.org = this.route.snapshot.queryParams["org"];
    if(this.org=="DSGO") this.proffesional.FkInstitution = 1;
    this.subscription = this.route.params.subscribe(params => {
         this.id = parseInt(params['id']);
         //only profile owner can edit
         if(this._userModel.user){
           this.isDSGO = (this._userModel.user.PkUser == this.dsgo)
           this.isAdmin = (this._userModel.user.email == 'admin@admin.com')
         }
         if(!isNaN(this.id)){
          if((this._userModel.user && this._userModel.user.PkUser==this.id) || (this.isAdmin || this.isDSGO))
           this._api.getProffesionalByUser(this.id)
              .subscribe(result => this.onProffesional(result));    
         } else this.router.navigate(['proform']);
       });
     window['initSearch'](); //google maps help
  }

  onSpecialties(data:object){
    var spec:Specialty;
    this.specialties = [];
    this.nonspecialties = [];
    for (var i = 0; i < data['length']; i++) {
      spec = data[i] as Specialty;
      if(spec.Dentist==1) this.specialties.push(spec)
      else this.nonspecialties.push(spec)
    }
  }

  onLanguages(data:object){
    var lang:Language
    this.languages = [];
    for (var i = 0; i < data['length']; i++) {
      lang = data[i] as Language;
      this.languages.push(lang)
    }
  }

  addSpecialtyProp(evt){
    this.specLabel = '';
    var spec:Specialty = this.specialties.filter(item => item['PkSpecialty'] == evt.target.value)[0];
    this.assignedSpecialties.push(spec)
    for (var i = 0; i < this.assignedSpecialties.length; i++) {
      spec = this.assignedSpecialties[i];
      this.specLabel += spec.Name+' ';
    }
  }

  removeCat(id, name){
    var sel = id;
    for(var z:number = 0; z<this.assignedSpecialties.length; z++){
      if(this.assignedSpecialties[z]['PkSpecialty']==parseInt(sel)){
        this.assignedSpecialties.splice(z, 1);
        break;
      }
    }         
  } 

   ngAfterViewInit(){
     setTimeout(function(w){
       w.hideErrors();
     },1000, this)
    
  }

  onProffesional(data:object){
    this.user = this._userModel.user;
    this.user.password = '********'
    this.thisIsEdit = true;
    this.proffesional = data as Proffesional;   
    if(this.proffesional.PkProffesional) this.isSameUser = (this.user.PkUser!=this.proffesional.user.PkUser) 
     this.isDSGO = (this._userModel.user.PkUser == this.dsgo)
     this.isAdmin = (this._userModel.user.PkUser == this.admin)
     if(this.isAdmin) this.isSameUser = false;
     if(this.isDSGO && this.proffesional.FkInstitution == 1) this.isSameUser = false;
     //Admin can edit anything, DSGO can edit DSGO profiles
     if(this._userModel.user.PkUser!=this.proffesional.FkUser){
       if(!this.isAdmin){
         if(this.proffesional.FkInstitution==1 && !this.isDSGO){
           return this.router.navigate(['']);
         }
       }
     }
    //copy user email, this is not saved
    if(this.isDSGO || this.isAdmin) this.user.email = this.proffesional.user.email;
    this.proffesional.Image =(this.proffesional.Image.length)?this._api.domain+'images/proffesionals/'+this.proffesional.Image:'';
    //this.proffesional.Pdf =(this.proffesional.Pdf.length)?this._api.domain+'images/cvs/'+this.proffesional.Pdf:'';
    this.action = 'Update';
    //language
    if(this.proffesional.language){
      for (var z = 0; z< this.proffesional.language.length; z++) {
        this.assignedLanguages.push(this.proffesional.language[z])
      }
    }
    //files
    if(data['files']){
      for (var z = 0; z< data['files']['length']; z++) {
        this.currentfiles.push(data['files'][z])
      }
    }
    if(data['deleted_at']) this.hideProfile = true;
    //days of the week
    var days = [];
    if(data['Days']) days = data['Days'].split(',');    
    for (var i = 0; i < days.length; i++) {
      switch (days[i]) {
        case "m":
          $('#monday').attr("checked", true);
          break;
        case "t":
          $('#tuesday').attr("checked", true);
          break;
        case "w":
          $('#wednesday').attr("checked", true);
          break;
        case "th":
          $('#thursday').attr("checked", true);
          break;
        case "f":
          $('#friday').attr("checked", true);
          break;
        case "s":
          $('#saturday').attr("checked", true);
          break;
      }
    }
    //specialties
    var spec:Specialty;

    if(this.proffesional.specialty){
      var isProfSpec:boolean
      //console.log('Encuentro '+this.proffesional.specialty.length+' especialidades')
      for (var i = 0; i < this.proffesional.specialty.length; i++) {
        spec = this.proffesional.specialty[i];
        isProfSpec = spec.Dentist==1;
        spec.PkSpecialty = this.proffesional.specialty[i]['FkSpecialty']
        //console.log('Tengo specialties? '+this.specialties.length+' que es '+this.proffesional.specialty[i]['FkSpecialty']+'/'+this.proffesional.specialty[i]['Dentist'])
        if(spec.Valid) spec.Valid =  spec.Valid.substr(0,10);
        if(spec.LiaValid) spec.LiaValid =  spec.LiaValid.substr(0,10);
        if(this.specialties) spec.Name =(isProfSpec)?this.specialties.filter(item => item['PkSpecialty'] == spec.PkSpecialty)[0]['Name']:this.nonspecialties.filter(item => item['PkSpecialty'] == spec.PkSpecialty)[0]['Name'];
        if(isProfSpec) {
          this.specLabel += spec.Name+' ';
          this.assignedSpecialties.push(spec)
        } else this.assignedNonSpecialties.push(spec)
      }
    }
    for (var i = 0; i < this.assignedNonSpecialties.length; i++) {
      var item:Specialty = this.assignedNonSpecialties[i]
      //console.log("#ncp option[value="+item.PkSpecialty+"']");
      $("#ncp option[value='" + item.PkSpecialty + "']").attr('selected', 'selected');
    }
    for (i = 0; i < this.assignedLanguages.length; i++) {
      var lang:object = this.assignedLanguages[i]
      $("#lang option[value='" + lang['FkLanguage'] + "']").attr('selected', 'selected');
    }
    //google maps listener
    this.window['initSearch']();
  }

  onAddress(){    
    setTimeout(this.captureAddress, 1000, this);    
  }

   captureAddress(w){
    //315 Madison Avenue, New York, NY, USA   
    //need to capture from control, biodibg doesnt catch content 
     var addr = document.getElementById('Address')['value'];     
     var address = addr.split(',');          
     if(address.length>1){
       w.proffesional.Address = address[0].trim();
       w.proffesional.City = address[1].trim();
       w.proffesional.State = address[2].trim();
       //copy the values, binding was not fired
       w.proffesional.Zip = document.getElementById('zip')['value'];
       w.proffesional.Latitude = document.getElementById('Latitude')['value'];
       w.proffesional.Longitude = document.getElementById('Longitude')['value'];
    }       
  }

   loadFile(evt:any){
    //preview
    if (evt.target.files && evt.target.files[0]) {
      var reader = new FileReader();

      reader.onload = function(e) {
        $('#Image').attr('src', e.target['result']);
        $('#Image').css('max-width', 500);
        $('#Image').css('max-height', 500)
      }

      reader.readAsDataURL(evt.target.files[0]);
    }
    //store
    let fileList: FileList = evt.target.files;
    if (fileList.length == 0) {
      console.log("No file selected!");
      return
    }
    let file: File = fileList[0];
    this.file = file; 
  }

  confirmHide(event){
    if(event.target.checked){
      if(confirm('Are you sure wou want to hide your profile?')){
        this._api.deleteProffesional(this.proffesional.PkProffesional)
          .subscribe(result=>this.onHide(result))
      }
    } else {
        this.makeVisible();
    }
  }

  confirmDelete(){
      if(confirm('Are you sure wou want to delete your profile?')){
        this._api.deleteProffesional(this.proffesional.PkProffesional, true)
          .subscribe(result=>this.onDelete(result))
      }
  }
  makeVisible(){
     this._api.restoreProffesional(this.proffesional.PkProffesional)
          .subscribe(result=>this.onUnHide(result))
  }

  onHide(data:object){
    this.hideProfile = (data['msg'].indexOf('deleted')>0);
    if(this.hideProfile) alert('Your profile is hidden');
    else this.proffesional['deleted_at'] = null;
  }

  onUnHide(data:object){
    alert('Your profile now is visible');
    this.proffesional['deleted_at'] = null;
  }

  onDelete(data:object){
    var ok = (data['msg'].indexOf('deleted')>0);
    if(ok) {
      alert('The profile was deleted');
      this.router.navigate(['/listprof'])
    } else alert('Error deleting the profile')
  }

  storePdf(evt){
     //store
    let fileList: FileList = evt.target.files;
    if (fileList.length == 0) {
      console.log("No file selected!");
      return
    }
    //let file: File = fileList[0];
    this.pdfFiles = fileList; 
  }
  //delete an attached file
 deleteFile(PkFile:number){
   this._api.deletePdf(PkFile)
     .subscribe(result => this.onPdfDelete(result))
 }

 onPdfDelete(data:object){ 
      data = data['data']; 
      this.currentfiles = [];
      for (var z = 0; z< data['length']; z++) {
        this.currentfiles.push(data[z])
      }   
 }

  save(){
    this.hideErrors();
    //days of the week
    var days:string[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var daysSelected:string[] = [];    
    for (var i = 0; i < days.length; i++) {
       if($('#'+days[i]).is(":checked")) daysSelected.push($('#'+days[i]).val())
         //else console.log(days[i]+' is unchecked '+$('#'+days[i]).is(":checked"))
     } 
     this.imgerror = '';
    this.proffesional.Days = daysSelected.toString();
    //if(this.hideProfile) return window['closeModal']('hidecannot', 'show');
    var error:boolean = false;
    this.generalError = '';
    //user
    for(var i:number = 0; i<10; i++) if(!this.proffesional[this.fields[i]] || this.proffesional[this.fields[i]].length<1){
      error = true;
      $('#'+this.fields[i]+'_error').show();
    }
    //user
    if(this.user.email.length<3 || !this._api.emailIsValid(this.user.email)) {error = true;  $('#email_error').show()}
    if(this.user.password.length<8) {error = true;  $('#password_error').show()}
    //at least 1 specialy or 1 non specialty
    var vals = $("#ncp").val(); //non professional
    if(this.assignedSpecialties.length<1 && vals.length<1){
      error = true;
      $("#Specialty_error").show();
    }
    //At least 1 Language
    var langs = $("#lang").val();
    if(langs.length<1) {
       error = true;
       $("#Language_error").show();
    }
    //check that prof specialties have License 
    if(this.assignedSpecialties.length>0){
      var isFineSpecialties = this.checkSpecialties();
      if(!isFineSpecialties) error = true;
    }
    this.user.FkType = 1;
    if(this.user.PkUser==0) this.password = this.user.password; //for login later
    //check there's an image
   /* if(!this.proffesional.Image && !this.file) {
      error = true;
      this.imgerror = "Image is mandatory"
    }*/
    
    //var userToUpdate = (this._userModel.user && (this._userModel.user.PkUser == this.proffesional.user.PkUser))
    if(!error){
        if(!this.user.PkUser || (this.proffesional.user && (this.user.PkUser==this.proffesional.user.PkUser)) ||
          (this.proffesional.user && (this.user.isAdmin)))
          this._api.createUser((this.user.PkUser)?this.proffesional.user:this.user).subscribe(result => this.onSaveUser(result));
      else 
         this._api.createProffesional(this.proffesional, this.file, this.pdfFiles)
            .subscribe(result => this.onSaveProf(result));
    } else {
      this.generalError = "MISSING DATA: PLEASE REVIEW THE FORM AND COMPLETE ALL MANDATORY FIELDS"
    }   
  }

   hideErrors(){
    for (var i = 0; i < this.fields.length; i++) $('#'+this.fields[i]+'_error').hide();
    $('#Specialty_error').hide();
    $("#Language_error").hide();
    this.generalError = '';
  }

  onSaveUser(data:object){
    if(data['success']==true || data['status']=='success'){
      this.proffesional.FkUser = data['data']['PkUser'];
      this.user = data['data'] as User;
      this._api.createProffesional(this.proffesional, this.file, this.pdfFiles)
        .subscribe(result => this.onSaveProf(result));
    } else this.generalError = "The user couldn't be created"
  }

  checkSpecialties(){
    var valid:boolean = true;
    var now = new Date();
    for (var i = 0; i < this.assignedSpecialties.length; i++) {
      var id:number = this.assignedSpecialties[i].PkSpecialty;
      var item = this.assignedSpecialties[i]; 
      item.License = $('#cert_'+id).val();
      item.Valid = $('#valid_'+id).val();
      if(item.License.length<3){
        valid = false;
        $('#License_error_'+id).text((item.Name=='EFDA')?'School Name is mandatory':'License is mandatory'); 
      } else $('#License_error_'+id).text('') 
      var due = new Date(item.Valid)
      if(!item.Valid || (item.Name=='EFDA' || item.Name=='Radiology')?due>now:due<now){
        valid = false;
        $('#Valid_error_'+id).text( (item.Name=='EFDA' || item.Name=='Radiology')?'Issue date should be in the past':'License should not be expired') 
      } else $('#Valid_error_'+id).text('') 
    }  
    return valid; 
  }

  onSaveProf(data:object){
    if(data['success']==true || data['status']=='success') {
       var PkProffesional:number = data['data']['PkProffesional'];
       this.proffesional.PkProffesional = PkProffesional;
        //complete data
        for (var i = 0; i < this.assignedSpecialties.length; i++) {
          this.assignedSpecialties[i].License = $('#cert_'+this.assignedSpecialties[i].PkSpecialty).val();
          this.assignedSpecialties[i].Valid = $('#valid_'+this.assignedSpecialties[i].PkSpecialty).val();
           this.assignedSpecialties[i].Liability = $('#liability_'+this.assignedSpecialties[i].PkSpecialty).val();
          this.assignedSpecialties[i].LiaValid = $('#liability_exp_'+this.assignedSpecialties[i].PkSpecialty).val();
          this.assignedSpecialties[i].Dentist = 1;
        }
        //collect languages
        var vals = $("#lang").val(); 
        var lang;
        this.assignedLanguages = [];
        for (var i = 0; i < vals.length; i++) {
          lang = {FkProffesional:this.proffesional.PkProffesional,FkLanguage:vals[i]}
          this.assignedLanguages.push(lang);  
        }
        this._api.addProfLanguages(this.assignedLanguages)
          .subscribe(result =>console.log(result));
        //non profesional specialties, add
        var vals = $("#ncp").val();
        for (i = 0; i < vals.length; i++) {
          var spec = new Specialty(vals[i], '','','','','',0);
          this.assignedSpecialties.push(spec);
        }
        this._api.createSpecialties(PkProffesional,this.assignedSpecialties)
        .subscribe(result => (this.thisIsEdit)?this.onFinish(result):this.onLogin(result));
    } else this.generalError = "The proffesional couldn't be created"
  }

  onLogin(data:object){
    this._api.getToken(this.user.email, this.password)
      .subscribe(result=>this.onToken(result))
  }

 onToken(data:object){
    var token:string = data["token"];
    if(!token) {      
      this.generalError = "User or password doesn't match";
    }  else { //tengo el token, me logueo
       localStorage.setItem('access_token', token);
       this._userModel.user = this.user;
       this._api.loginUser(this.user.email, this.password)
        .subscribe(result=>this.onFinish(result))
    }
  }

  unsubscribe(){
    window['closeModal']('unsubscribe', 'hide');
    $("body").removeClass("modal-open");
    $('.modal-backdrop').remove();
    this._api.unsubscribe(this.user.PkUser, this.reason)
        .subscribe(result=>this.onLogout(result))
  }

  onLogout(data:object){
    window.localStorage.clear()
    this.router.navigate(['/'])
  }
  
  onFinish(data:object){
    console.log('Es edit? '+this.thisIsEdit)
     if(!this.thisIsEdit){
       this._api.addToDaylite(this.proffesional.PkProffesional).subscribe(result=>console.log(result));       
     } 
     this.router.navigate(['/prodetail/'+this.proffesional.PkProffesional]);
  }
}
