import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-title-box',
  templateUrl: './title-box.component.html',
  styleUrls: ['./title-box.component.css'],
  inputs: ['title']
})
export class TitleBoxComponent implements OnInit {
	public title:string = '';

  constructor(private _location: Location) { }

  ngOnInit() {
  }

  goBack(){
  	this._location.back();
  }
}
