import { Component, OnInit,ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ApiService } from '../services/api.service';
import { Proffesional, User } from '../model/dto';
import {BaseModel} from '../model/base.model';
import {UserModel} from '../model/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbModal, NgbModalRef, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-profesionalist',
  templateUrl: './profesionalist.component.html',
  styleUrls: ['./profesionalist.component.css'] 
})

export class ProfesionalistComponent implements OnInit {
  @ViewChild('content') content: ViewChild;
  
  subscription:any;
  subscription2:any;
  public proffesionals:Proffesional[];
  public specialties:object;
  public pages:number[];
  public currentPage:any = 1;
  public itemsPerPage:number;
  public currentLetterPage:string;
  public totalSearchResult:string;
  public total:number = 1;
  public filterName:string = 'Select filter';
  public filterValue:string = '';
  public isCollapsed:boolean = true;
  public isMAsearchCollapsed:boolean = true;
  public letters:string[] = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  public usingLetterPaging:boolean=false;
  protected checkAllBoxes:boolean = false;
  public user:User;
  public adminLists:object[];
  public profSel:number = 0;
  public auxModal:NgbModalRef;
  public listname:number;  
  public isMobile:boolean = false;
  public showPagination:boolean = true;
  public sellist:string = '';
  public selListId:number = 0;

  constructor(private _api:ApiService, private _baseModel:BaseModel, private route:ActivatedRoute,
              private _userModel:UserModel, private modalService: NgbModal, private router:Router,
              private location: Location) {
    this.subscription = this._baseModel.docItem$.subscribe(
        item => this.changed(item)
    );
    this.user = this._userModel.user;

    if(window){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) this.isMobile = true;    
    } else {
      this.isMobile = true;
    }
    if(this.user.FkType>=2){
      this._api.getLists(this.user.PkUser)
        .subscribe(result=>this.onLists(result))
    }
     this.subscription = route.params.subscribe(params => {
         if(!isNaN(parseInt(params['page']))) {
           this.currentPage = parseInt(params['page']);
         } else {
           this.filterValue = params['page'];
         }
           
     });
   }

   onLists(data:object){     
    this.adminLists = [];
    var tot = data['length']
    for (var i = 0; i < tot; i++) {
     this.adminLists.push({label:data[i].Name, value:data[i].PkList});
    }
  }

  ngOnInit() {
    this._api.getSpecialties()
      .subscribe(result => this.onSpecialties(result));      
  }

  changed(item:Object){
    //console.log("Evt "+item['name']);//+":"+item['value'])
    //for(var prop in item['value']) console.log(prop+": "+item['value'][prop])
    let key:string = item["name"];
    let value:any = item["value"];
    if(key=="filterProf") {
      this.showPagination = false;
      this._api.filterProf(value)
       .subscribe(result => this.onProffesionals(result)) 
    }
    if(key=="searchProf"){
        this.showPagination = false;
        this._api.profSearch(value)
         .subscribe(result => this.onProffesionals({data:result}))  
          this.location.replaceState(
            this.router.createUrlTree(
                ['/listprof/'+value]
            ).toString()
        ); 
    }
  } 

  onSpecialties(data:object){
    this.specialties = {};
    for(var i:number = 0; i<data['length']; i++){
      this.specialties[data[i]['PkSpecialty']] = data[i]['Name'];
    }
    //var tmp = window.sessionStorage.getItem('currentPage')
    //this.currentPage = (tmp && parseInt(tmp)>1)?parseInt(tmp):1;
   //console.log(this.currentPage+' / '+this.filterValue)
    if(this.filterValue && this.filterValue.length>3){ //from search
        this._api.profSearch(this.filterValue)
         .subscribe(result => this.onProffesionals({data:result}))  
      
    } else { //pagination */

      if(isNaN(parseInt(this.currentPage))) {
        this.currentPage = 1;
        this.showPagination = true;
      } 
        this._api.getProffesionals(this.currentPage)
          .subscribe(result => this.onProffesionals(result)); 
    }
  }

  checkAll(){
    this.checkAllBoxes = !this.checkAllBoxes;
    var tot:number = this.proffesionals.length
    for (var i = 0; i < tot; i++) {
      $('#'+this.proffesionals[i].PkProffesional).prop('checked', this.checkAllBoxes);
    }
  }

  listOption(){
    this.selListId = this.listname;
    this.sellist = $("#listname option:selected" ).text();
  }

  addToList(){
    var tot:number = this.proffesionals.length
    this.profSel = 0;
    for (var i = 0; i < tot; i++) {
      if($('#'+this.proffesionals[i].PkProffesional).prop('checked')) this.profSel++;
    }
    this.auxModal = this.modalService.open(this.content);
  }

  saveToList(){
    var sel:number[] = [];
    var tot:number = this.proffesionals.length
    for (var i = 0; i < tot; i++) {
      if($('#'+this.proffesionals[i].PkProffesional).prop('checked')) {
        sel.push(this.proffesionals[i].PkProffesional)        
      }
    }
    var userId:number = this.user.PkUser
    this._api.saveToList(this.selListId, sel, this.sellist, userId)
      .subscribe(result=>this.onAddedToList(result))
  }

  onAddedToList(data:object){
    this.auxModal.close();
    if(data['success']==true) {
      var cant:number = data['data']['length'];
      this.sellist = '';
      this.selListId = 0;
      this._api.getLists(this.user.PkUser)
        .subscribe(result=>this.onLists(result))
      alert(data['message']);
    }
  }

  onProffesionals(data:object){
  	this.proffesionals = []; 
  	this.total = parseInt(data['total']);
  	this.currentPage = parseInt(data['current_page']);
    this.itemsPerPage = parseInt(data['per_page'])
  	this.pages = [];
    var item:string;
    var proffesional:Proffesional
  	for (var i = 0; i<this.total; i++) this.pages.push(i+1);
     // console.log('Encuentra '+ data['data']['length']);
  	for (i = 0; i < data['data']['length']; i++) {
  		proffesional = data['data'][i] as Proffesional;
      proffesional.SpecialtyName = '';
      proffesional.Image = this._api.domain+'images/proffesionals/pics/'+proffesional.Image;
      proffesional.FullAddress = proffesional.City+' '+proffesional.Zip+', '+proffesional.State      
      for (var z = 0; z < proffesional.specialty.length; z++) {
         item = this.specialties[proffesional.specialty[z]['FkSpecialty']] as string;
         proffesional.SpecialtyName += item+', '         
      }
     proffesional.SpecialtyName = proffesional.SpecialtyName.substr(0, proffesional.SpecialtyName.length-2);
  		this.proffesionals.push(proffesional);
  	}
    this.totalSearchResult = (data['total'])?data['total']:this.proffesionals.length
    window.sessionStorage.setItem('currentPage', this.currentPage.toString());
  }

  loadPage(page:number){
      this._api.getProffesionals(page)
  			.subscribe(result => this.onProffesionals(result));
      //window['location'].href  = "/listprof/"+page;
      this.router.navigate(['/listprof/'+page])
    }

  filterPage(letter:string){
    this.usingLetterPaging = true;
    this.currentLetterPage = letter;
    this.showPagination = false;
    this._api.getProffesionalsByLetter(letter)
      .subscribe(result => this.onProffesionals(result));
  }

}