<div class="container bg-white">
	<div class="row inside">
		<div  *ngIf="!twilioId" class="card w-100 mt-4 mr-4 ml-4"> <!-- init card -->
			<div class="card-header text-center">
				<h5>Connect your account to receive payments</h5>
         	 </div>
         	 <div class="card-body">
	            <p>&nbsp;</p>
				<p class="ml-4">
				<style type="text/css">
					#twilio-connect-button {
						background: url(https://www.twilio.com/bundles/connect-apps/img/connect-button.png);
						width: 130px; height: 34px; display: block; margin: 0 auto;
					}
					#twilio-connect-button:hover { background-position: 0 34px; }
				</style>
				<a href="https://www.twilio.com/authorize/CN386472192d8f3acff2cd0c3e59ceb3e6" id="twilio-connect-button"></a>
				</p>
				<p>&nbsp;</p>
	            <p>&nbsp;</p>
	            <p>&nbsp;</p>
	            <p>&nbsp;</p>
			</div>				
        </div>
		<div  *ngIf="twilioId" class="card w-100 mt-4 mr-4 ml-4"> <!-- init card -->
          <div class="card-header">
            Account details
          </div>
          <div class="card-body">
            <h5 class="card-title">Transforma Connect account for Twilio</h5>
            <p class="card-text">Current twilio ID {{twilioId}}</p>
            <div class="d-flex justify-content-end"></div>
           
		</div>
			 <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
	</div>
</div>