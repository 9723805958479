import { Component, OnInit, EventEmitter } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.css'],
  inputs: ['type'],
  outputs: ['broadcast']
})
export class SendMessageComponent implements OnInit {
	public type:number;
	public errorMsg:string = '';
	public title:string;
  public message:string;
  public broadcast: EventEmitter<any> = new EventEmitter();
  public sendSms:number = 0;

  constructor() { }

  ngOnInit() {
  }

  sendMessage(){
    this.errorMsg = '';
    if(!this.title || this.title.length<3) this.errorMsg = "Title is mandatory";
    else if(!this.message || this.message.length<3) this.errorMsg = "Message is mandatory";       
    if(this.errorMsg=='') {
      this.broadcast.emit({name:'message', value:this.title+'|'+this.message+'|'+this.sendSms});
      $('#title').prop('disabled', true);
      $('#message').prop('disabled', true);
      $('#send').prop('disabled', true);
     }
  } 

  addToMyList(){
    this.broadcast.emit({name:'saveToMyList'});
    //$('#sendToList').prop('disabled', true);
  }
}
