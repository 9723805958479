import { Component, OnInit,ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import {UserModel} from '../model/user.model';
import { Proffesional, User } from '../model/dto';
import {BaseModel} from '../model/base.model';
import {NgbModal, NgbModalRef, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'app-my-list',
  templateUrl: './my-list.component.html',
  styleUrls: ['./my-list.component.css']
})
export class MyListComponent implements OnInit {
   @ViewChild('content') content: ViewChild;
   subscription:any;
  public proffesionals:Proffesional[];
  public specialties:object;
  public filterName:string = 'Select filter';
  public filterValue:string = '';
  public isCollapsed:boolean = true;
  public isMAsearchCollapsed:boolean = true;
  public pages:number[];
  public currentPage:number = 1;
  public total:number = 1;
  public listname:string = '';
  public listerror = "";
  protected user:User;
  protected isAdmin:boolean;
  protected auxModal:NgbModalRef;
  protected selId:number; 
  public checkAllBoxes:boolean = false;
  public profSel:number = 0;

  constructor(private _api:ApiService, private route:ActivatedRoute, private _baseModel:BaseModel, 
    private _userModel:UserModel, private modalService: NgbModal) {
    this.subscription = this._baseModel.docItem$.subscribe(
        item => this.changed(item)
    );
    this.user = this._userModel.user
    this.isAdmin = this.user.isAdmin;
   }

  ngOnInit() {
    this._api.getSpecialties()
      .subscribe(result => this.onSpecialties(result)); 

    this.subscription = this.route.params.subscribe(params => {
         var id:number = parseInt(params['id']);
         if(!isNaN(id))
            this._api.getMyList(id)
              .subscribe(result => this.onProffesionals(result)); 
       });  
  }

  changed(item:Object){
    //console.log("Evt "+item['name']+":"+item['value'])
    //for(var prop in item['value']) console.log(prop+": "+item['value'][prop])
    let key:string = item["name"];
    let value:any = item["value"];
    if(key=="filterProf") {
      this._api.filterProf(value)
       .subscribe(result => this.onProffesionals(result))  
    }

    if(key=="searchList"){
        this.currentPage = 1;
        this._api.profSearchList(value, this.selId)
         .subscribe(result => this.onProffesionals({data:result}))  
    }
    if(key=='createList') this.auxModal = this.modalService.open(this.content);
    if(key=='filterList') this.getList(value) 
  }

  onSpecialties(data:object){
    this.specialties = {};
    for(var i:number = 0; i<data['length']; i++){
      this.specialties[data[i]['PkSpecialty']] = data[i]['Name'];
    }
  }

   checkAll(){
    this.checkAllBoxes = !this.checkAllBoxes;
    var tot:number = this.proffesionals.length
    for (var i = 0; i < tot; i++) {
      $('#'+this.proffesionals[i].PkProffesional).prop('checked', this.checkAllBoxes);
    }
  }

  deleteProf(){
    var tot:number = this.proffesionals.length
    this.profSel = 0;
    for (var i = 0; i < tot; i++) {
      if($('#'+this.proffesionals[i].PkProffesional).prop('checked')) this.profSel++;
    }
    if(this.profSel==0) return alert('Should select some proffesional')
    //this.auxModal = this.modalService.open(this.content);
    if(confirm('Are you sure to delete this proffesionals from this list?')){
      this.deleteFromList();
    }
  }

   deleteFromList(){
    var sel:number[] = [];
    var tot:number = this.proffesionals.length
    for (var i = 0; i < tot; i++) {
      if($('#'+this.proffesionals[i].PkProffesional).prop('checked')) {
        sel.push(this.proffesionals[i].PkProffesional)        
      }
    }
    
    this._api.deleteFromList(this.selId, sel)
      .subscribe(result=>this.onDeletedFromList(result))
  }

  onDeletedFromList(data:object){
    if(data['success']==true) {
      var cant:number = data['data']['length']
      alert(data['message']);
    }
    this.getList(this.selId);
  }

  getList(id:number){
    this.selId = id;
    this.currentPage = 1;
    this._api.getList(id, this.currentPage)
      .subscribe(result=>this.onProffesionals(result))
  }

  onFilteredList(data:object){
    console.log(data);
  }

  onProffesionals(data:object){    
    this.proffesionals = [];
    this.total = parseInt(data['last_page']);
    this.currentPage =(data['current_page'])?parseInt(data['current_page']):1;
    this.pages = [];
    var item:string;
    var proffesional:Proffesional
    for (var i = 0; i<this.total; i++) this.pages.push(i+1);
     // console.log('Encuentra '+ data['data']['length']);
    data = data['data'];
    if(data)
    for (var i = 0; i < data['length']; i++) {
      //console.log('Agrego '+ data[i].LastName)
      proffesional = data[i] as Proffesional;
      proffesional.SpecialtyName = '';
      for (var z = 0; z < proffesional.specialty.length; z++) {
         item = this.specialties[proffesional.specialty[z]['FkSpecialty']] as string;
         proffesional.SpecialtyName += item+', '
      }
     proffesional.SpecialtyName = proffesional.SpecialtyName.substr(0, proffesional.SpecialtyName.length-2);
      this.proffesionals.push(proffesional);
    }
    //console.log("Hay "+this.proffesionals.length)
  }

  loadPage(page:number){
      this._api.getList(this.selId, page)
        .subscribe(result => this.onProffesionals(result));
  }

  saveList(){
    var PkUser = this._userModel.user.PkUser;
    if(this.listname=='') this.listerror = 'Name is mandatory !';
    else{
      this.listerror = "";
      this.auxModal.close();
      this._api.createList(this.listname, PkUser)
        .subscribe(result=>this.onListCreated(result))
      }
  }
  onListCreated(data:object){    
    if(data['PkList']) {
      this._baseModel.setProp({name:'lista', value:{label:data['Name'], value:data['PkList']}});
      alert('List created successfully');
    }
    this.listname = "";
  }
}