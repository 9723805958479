  <app-navigation></app-navigation> 
 
   <app-search-box mode="2"></app-search-box>

   <div class="container">
    <table class="table table-bordered table-hover bg-white">
      <thead class="thead-light" >
        <tr>
          <th scope="col">Practice</th>
          <th scope="col">City</th>
          <th scope="col">Contact</th>
          <th scope="col">Address</th>
        </tr>
       
      </thead>
      <tbody *ngIf="offices">
        <tr *ngFor="let item of offices">
          <th scope="row"><a href="javascript:void(0)" routerLink="/officedetail/{{item.PkOffice}}">{{item.Name}}</a></th>
          <td>{{item.City}}</td>
          <td>{{item.Contact}}</td>
          <td>{{item.Address | truncate : 50}}</td>
        </tr>
         <tr *ngIf="offices.length<1"><td colspan="4">No offices found</td></tr>
      </tbody>
    </table>

  <nav aria-label="...">
    <ul class="pagination pagination-md">
      <li *ngFor="let item of pages"  [class]="(item==currentPage)?'page-item disabled':'page-item'"><a href="javascript:void(0)" class="page-link" (click)="loadPage(item)">{{item}}</a></li>
    </ul>
  </nav>
  <br>
</div>
<app-footer></app-footer>