<div class="wrapper wrapper_lg air air_horz">
    <div class="row">
        <div class="flex-column col-lg-8 l-white justify-content-end">      
            <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
            <div class="row mb-4"><div class="d-flex col-12 justify-content-center"><span class="h3">Payment</span></div></div> <!-- separa -->
             <div class="row mt-8 ml-1"><!-- ROW --> 
             	<div class="col-md-1">&nbsp;</div>                    
                <div class="d-flex col-md-10 justify-content-center">
                  Your payment was processed succesfully!
                </div>
                <div class="col-md-1">&nbsp;</div> 
			</div><!-- END ROW -->
			 <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
            <div class="row mt-8 ml-1" *ngIf="payment"><!-- ROW -->
            	<div class="col-md-1">&nbsp;</div>                    
                <div class="col-md-10">
                   <div class="card text-center" *ngIf="office">
					  <div class="card-body">
					    <h5 class="card-title">Payment confirmation</h5>
					    <p class="card-text">Plan selected: {{payment.Alias}}</p>
					    <p class="card-text">For office: {{office.Name}} - {{office.Contact}}</p>
					    <p *ngIf="payment.StripeSubscription" class="card-text">Confirmation code: {{payment.StripeSubscription}}</p>
					     <p *ngIf="!payment.StripeSubscription" class="card-text">Confirmation code: {{payment.StripeId}}</p>
					  </div>
					  <div class="card-footer text-muted">
					    <small>Payment processed by stripe</small>
					  </div>
					</div>
                </div>
                <div class="col-md-1">&nbsp;</div> 
			</div><!-- END ROW -->
			 <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
             
            <div class="row mt-8">
            	<div class="col-md-1">&nbsp;</div>
            	<div class="d-flex col-md-10 justify-content-center"><img src="assets/img/logos/powered_by_stripe.png"></div>
            	<div class="col-md-1">&nbsp;</div>
            </div>
            <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
			<div class="row mt-8"><!-- ROW -->
				<div class="col-md-1">&nbsp;</div>
               <div class="d-fle col-md-10 justify-content-center">
					<button class="btn w-100 btn-large btn-secondary" routerLink="/listprof">Go to professional listing</button>
              </div>
              <div class="col-md-1">&nbsp;</div>
            </div><!-- separa -->
			<div class="row mt-8"><div class="col-md-12">&nbsp;</div></div> <!-- separa -->
        </div>
        <div class="flex-column col-lg-4 l-grey d-none d-lg-flex">
           <app-login-form></app-login-form>
        <div>
    </div>
</div>
<app-footer></app-footer>