  <app-navigation></app-navigation> 
 
   <app-title-box title="Messages"></app-title-box>

   <div class="container bg-white">
    <ngb-tabset (tabChange)="tabChange($event)" [destroyOnHide]="false" #t="ngbTabset">
      <ngb-tab title="Received" id="Received">
        <ng-template ngbTabContent>
          <table class="table table-bordered table-hover bg-white">
            <tbody *ngIf="received">
              <tr *ngFor="let item of received">
                <td *ngIf="item.Check==0"><a href="javascript:void(0)" routerLink="/messagedetail/{{item.PkMessage}}"><i class="fa fa-envelope" ngbTooltip="Msg not viewed"></i>&nbsp;
                  <i *ngIf="item.SMS==true" class="fas fa-comment" ngbTooltip="SMS sent"></i></a>
                </td>
                <td *ngIf="item.Check==1"><a href="javascript:void(0)" routerLink="/messagedetail/{{item.PkMessage}}"><i class="fa fa-envelope-open" ngbTooltip="Msg viewed"></i>
                 &nbsp;<i *ngIf="item.SMS==true" class="fas fa-comment" ngbTooltip="SMS sent"></i></a>
                </td>
                <td>{{item.Title}}<br>{{item.Date}}</td>                
                <td><a href="javascript:void(0)" [routerLink]="item.fromlink">{{item.Label}}</a></td>
                <td>{{item.Message | truncate : 50}}</td>
                <td><a href="javascript:void(0)" (click)="deleteMsg(item.PkMessage, false)"><i class="fa fa-trash-alt"></i></a></td>
              </tr>
               <tr *ngIf="received.length<1"><td colspan="4">No messages found</td></tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
        </ng-template>
      </ngb-tab>
       <ngb-tab title="Sent" id="Sent">
        <ng-template ngbTabContent>
          <table class="table table-bordered table-hover bg-white">
            <tbody *ngIf="sent">
              <tr *ngFor="let item of sent">
                <td *ngIf="item.Check==0">
                  <a href="javascript:void(0)" routerLink="/messagedetail/{{item.PkMessage}}"><i class="fa fa-envelope" ngbTooltip="Msg not viewed"></i>&nbsp;<i *ngIf="item.SMS==true" class="fas fa-comment" ngbTooltip="SMS sent"></i> </a>       
                </td>
                <td *ngIf="item.Check==1">
                  <a href="javascript:void(0)" routerLink="/messagedetail/{{item.PkMessage}}"><i class="fa fa-envelope-open" ngbTooltip="Msg viewed"></i>
                  &nbsp;<i *ngIf="item.SMS==true" class="fas fa-comment" ngbTooltip="SMS sent"></i></a>
                </td>
                <td>{{item.Title}}<br>{{item.Date}}</td>
                <td><a href="javascript:void(0)" [routerLink]="item.tolink">{{item.Label}}</a></td>
                <td>{{item.Message | truncate : 20}}</td>
                <td><a href="javascript:void(0)" (click)="deleteMsg(item.PkMessage, true)"><i class="fa fa-trash-alt"></i></a></td>
              </tr>
               <tr *ngIf="sent.length<1"><td colspan="4">No messages found</td></tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="user.FkType>1" title="List Message" id="ListMessages">
        <ng-template ngbTabContent>         
           <div class="row w-100 mb-2 mt-4">      
            <div class="col-11">
              <select class="form-control" name="lista" id="lista" [(ngModel)]="selLista">
                <option value="0">Select list</option>
                <option *ngFor="let item of adminLists" [value]="item.value">{{item.label}}</option>
              </select>   
            </div>
            <div class="col-1">&nbsp;</div>
          </div>
           <div class="row w-100 mb-4">      
            <div class="col-11">                
                <input type="text" class="form-control" name="subject" id="subject" [(ngModel)]="subject" placeholder="Subject">        
            </div>
            <div class="col-1">&nbsp;</div>
          </div>
          <div class="row d-w-100"> 
              <div class="col-12 ml-1 mr-2">
                <span class="text-danger">Formatting and Subject not included in SMS.</span>
              </div>
          </div>  
           <div class="row w-100 mb-4 mt-4"> 
            <div class="col-12 ml-2">
              <ckeditor
                [(ngModel)]="ckeditorContent"
                [config]="{uiColor: '#CCCCCC',removeButtons: 'Image,Flash,PageBreak,Iframe,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,BidiLtr,BidiRtl,Language'}"
                [readonly]="false"
                (change)="onChange($event)"
                (editorChange)="onEditorChange($event)"
                (ready)="onReady($event)"
                (focus)="onFocus($event)"
                (blur)="onBlur($event)"
                (contentDom)="onContentDom($event)"         
                (paste)="onPaste($event)"
                (drop)="onDrop($event)"
                debounce="500">
              </ckeditor>
            </div>
          </div>
           <div class="row w-100 mb-4 mt-4">
            <div class="col-2">&nbsp;</div>
            <div class="d-flex col-8 justify-content-end">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="byEmail" name="byMail" value="true" [(ngModel)]="byMail">
                <label class="form-check-label" for="inlineCheckbox1">Send by Email</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="bySMS" id="bySMS" value="true"  [(ngModel)]="bySMS">
                <label class="form-check-label" for="inlineCheckbox2">Send by Text</label>
              </div>
              <button class="btn btn-outline-secondary" (click)="sendContent()">Send</button>
            </div>
            <div class="col-2">&nbsp;</div>
          </div>
          <p>&nbsp;</p>
        </ng-template>
      </ngb-tab>
       <ngb-tab *ngIf="user.FkType>1" title="Lists" id="Lists">
        <ng-template ngbTabContent>
          <div class="row w-100 mb-1 mt-4">      
              <div class="col-3">        
                <select class="form-control-xs" name="listaprof" id="listaprof" [(ngModel)]="selLista" (change)="onListSelect()">
                  <option value="0">Select</option>
                  <option *ngFor="let item of adminLists" [value]="item.value">{{item.label}}</option>
                </select>&nbsp;           
              </div>
              <div class="col-9">
                  <input type="text" class="form-control ml-2" name="newListMobile" placeholder="List name" [(ngModel)]="newListMobile">
              </div>
          </div>
          <div class="row w-100 mb-1">
                <div class="col-5">&nbsp;</div>
               <div class="col-5">
                    <button class="btn-xs btn-outline-secondary" (click)="createList(isMobile)">Create</button>&nbsp;<button class="btn-xs btn-outline-danger" (click)="deleteList(isMobile)">Delete</button>  
                </div>
            </div>
             <div class="row w-100 mb-1 mt-1">
                <div class="col-2">&nbsp;</div>
               <div class="col-8"><span class="text-danger">{{listError}}</span></div> 
               <div class="col-2">&nbsp;</div>
              </div>
             <table  *ngIf="!this.isMobile" class="table table-bordered table-hover bg-white">
              <thead class="thead-light" >
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">City</th>
                  <th scope="col">State</th>
                  <th scope="col">Specialty</th>
                  <th scope="col">Hourly</th>
                  <th scope="col">Since</th>
                  <th scope="col"><input type="checkbox" name="selectall" id="selectall" (click)="checkAll()"></th>
                </tr>
               
              </thead>
              <tbody *ngIf="proffesionals">
                <tr *ngFor="let item of proffesionals">
                  <th scope="row"><a href="javascript:void" routerLink="/prodetail/{{item.PkProffesional}}">{{item.FirstName}} {{item.Middlename}} {{item.LastName}}</a></th>
                  <td>{{item.City}}</td>
                  <td>{{item.State}}</td>
                  <td>{{item.SpecialtyName}}</td>
                  <td>${{item.HourlyRate}}</td>
                  <td>{{item.Experience}}</td>
                  <td><input type="checkbox" [name]="item.PkProffesional" [id]="item.PkProffesional"></td>
                </tr>
                 <tr *ngIf="!proffesionals.length || proffesionals.length<1"><td colspan="7">Please select a list</td></tr>
              </tbody>    
            </table>
             <table  *ngIf="this.isMobile" class="table table-bordered table-hover bg-white">
             <thead class="thead-light"> 
                <tr>             
                    <th width="30%" scope="col">Name</th>
                    <th width="20%" scope="col">City</th>
                    <th width="40%" scope="col">Specialty / Hourly Rate</th>
                    <th width="10%" scope="col"><input type="checkbox" name="selectall" id="selectall" (click)="checkAll()"></th>
                </tr>
              </thead>
               <tbody *ngIf="proffesionals">
                 <tr *ngFor="let item of proffesionals">
                    <td width="25%"><img src="{{item.Image}}" class="listImg"><br>
                      <a href="javascript:void" routerLink="/prodetail/{{item.PkProffesional}}">{{item.FirstName}} {{item.Middlename}} {{item.LastName}}</a></td>
                    <td width="30%">{{item.FullAddress}}</td>
                    <td width="40%">{{item.SpecialtyName}}<br>${{item.HourlyRate}}</td>
                     <td width="5%"><input type="checkbox" [name]="item.PkProffesional" [id]="item.PkProffesional"></td>
                </tr>
                 <tr *ngIf="proffesionals.length<1"><td  colspan="4">No professionals found</td></tr>
              </tbody>    
            </table>
            <div class="d-flex w-100 justify-content-end">
              <button class="btn btn-outline-secondary" (click)="removeFromList()">Remove from list</button>
            </div>
            <hr>
             <nav aria-label="...">
              <ul class="pagination pagination-md">
              <li *ngFor="let item of pages"  [class]="(item==currentPage)?'page-item disabled':'page-item'"><a href="javascript:void(0)" class="page-link" (click)="loadPage(item)">{{item}}</a></li>
            </ul>
            </nav>
            <p>&nbsp;</p>
        </ng-template>
      </ngb-tab>

<div class="row" *ngIf="sent">
      <br><br><br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span><b>Received:</b> {{received.length}} messages.&nbsp;&nbsp;&nbsp;&nbsp;</span><br>
      <span><b>Sent:</b> {{sent.length}} messages.&nbsp;&nbsp;&nbsp;</span><br><br>
      <span>Select the tab to see the messagges.</span><br><br>
</div>
<!--
  <nav aria-label="...">
    <ul class="pagination pagination-md">
      <li *ngFor="let item of pages"  [class]="(item==currentPage)?'page-item disabled':'page-item'"><a href="javascript:void(0)" class="page-link" (click)="loadPage(item)">{{item}}</a></li>
    </ul>
  </nav>-->
  <br>

<app-footer></app-footer>