import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Pageable } from '../model/pageable.container';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Page } from '../model/dto';

/********************************************
* Base Model holds all common methods       *
* notabily the pageable elements and all    *
* his methods                               *
********************************************/
export class BaseModel {
	
	private doc = new BehaviorSubject<Object>({});
	public docItem$ = this.doc.asObservable();
	//pageable list of users, we need to keep track how many were loaded
	protected _list:Pageable = new Pageable();
	//carga y termina son flags para el preloader
	private _marketplaceId:number;
	private carga:number;
	private termina:number;
	
	constructor() { }
	
	setProp(data: Object) {
		//console.log("MODEL " + data["name"] + "=" + data["value"]);
		this[data["name"]] = data["value"];
		this.doc.next(data);
	}

	getPage(page:number){ return this._list.getPage(page) }
	setCurrentPage(page:number){ this._list.current_page = page; }
	setNumb(cant:number){ this._list.numb = cant; }
	addElement(item:Object){ this._list.addElement(item); }
	//getter/setter for users
	set list(items:object[]) { this._list.list = items }
	get list():object[]{ return this._list.list }
	//get the pageable object
	get pageable():Pageable{ return this._list }
	get cant(){ return this._list.cant; }
	set marketplaceId(id:number) { this._marketplaceId = id }
	get marketplaceId():number{ return this._marketplaceId }
}