import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {UserModel} from '../model/user.model';
import { ApiService } from '../services/api.service';
import {User} from '../model/dto';

@Component({
  selector: 'app-connect-sms',
  templateUrl: './connect-sms.component.html',
  styleUrls: ['./connect-sms.component.css']
})
export class ConnectSmsComponent implements OnInit {
	subscription:any;
	public user:User;
	public code:string;
  public twilioId:string;
// http://localhost:4200/admin/payment?scope=read_write&code=ac_Dhb6v2Xb5XLNFtXLFQdK3pmcIyJW4Z4M
  constructor(private _api:ApiService,  
  			private route:ActivatedRoute, 
  			private _userModel:UserModel) { 
  	this.user = this._userModel.user;
  }

  ngOnInit() {
  	 
  	 this.subscription = this.route.queryParams.subscribe(params => {	  	 
	  	 this.code = params['AccountSid'];
	     if(this.code)
	        this._api.setTwilioId(this.code).subscribe(result => this.onTwilioId(result));
	      else
	      	this._api.getTwilioId().subscribe(result => this.onTwilioId(result));
	  })    
  }

  onTwilioId(data:object){
    if(data['success']!='false')
    	if(data['data'] && data['data']['Twilio'])
  			this.twilioId = data['data']['Twilio'] as string;
  }
}
