import { Component, Injectable, Output} from '@angular/core';
import { Page } from '../model/dto';

export class Pageable {
	
	//pageable list of elements, we need to keep track how many were loaded
	private _list:Object[] = [];
	public userPage:Page = new Page(0,50,0,0); //default
	
	private _currentPage:number = 1; //default
	private _nextPage:number;
	private _previousPage:number;
	private _firstPage:number;
	private _lastPage:number;
	private _total:number; //el total, aunque no haya sido cargado aun
	private _numb:number = 50; //quantity of elements per page

	constructor() { }

	getPage(page:number):Page{
		//simulate page mimic Laravel page object
		var first:number = ((page-1)*this._numb);
		var last:number = first+this._numb
		var pagedList:Object[] = this._list.slice(first, last);
		if(pagedList.length){
			this.userPage.current_page = this._currentPage =  page;
			this.userPage.last_page = this._lastPage;
			this.userPage.data = pagedList;
			this.userPage.total = pagedList.length;
			return this.userPage
		} else return new Page(0,0,0,0);
	}

	checkPage(page:number):boolean{
		return this.getPage(page).total>0;
	}

	addElement(item:Object){
		this._list.push(item);
	}
	removeElement(key:string, val:any){
		for (var i = 0; i < this._list.length; i++) {
			if(this._list[i][key]==val){
				this._list.splice(i, 1);
				break;
			}
		}
	}
	getElement(key:string, val:any){
		return this._list.filter(item => item[key] == val)[0];
	}
	public get allLoaded():Boolean{
		return this._total>0 && this._total<=this._list.length;
	}
	//getter/setter for list
	public get list():Object[]{ return this._list }
	public set list(list:Object[]) { this._list = list }
	//quantity of elements loaded
	public get cant(){ return (this._list)?this._list.length:0; }
	//quantity of elements loaded or not
	public set total(cant:number) { this._total = cant }
	public get total():number { return this._total}
	//getter for page position
	public get current_page():number { return this._currentPage;	}
	public set current_page(page:number) { this._currentPage = page	}
	public get next_page():number { return this._nextPage;	}
	public get previous_page():number { return this._previousPage; }
	public get last_page():number { return this._lastPage;	}
	public set last_page(page:number) { this._lastPage = page	}	
	public get first_page():number { return this._firstPage;	}
	//elements per page
	public get numb():number {return this._numb }
	public set numb(cant:number) { this._numb = cant }
}