   <div class="row mr-2"><!-- ROW -->                  
      <div class="col-md-12"> 
        <div class="form-group">
          <br><br><br>
          <p class="h4 text-left">Send Email</p>
          <br>
          <input type="text" class="form-control" name="title" id="title" [(ngModel)]="title" placeholder="Title">
          <br>
          <textarea rows="5" name="message" class="form-control" id="message" [(ngModel)]="message"></textarea>
          <br>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="sendasmessage" value="1" [(ngModel)]="sendSms">
            <label class="h6 form-check-label" for="exampleCheck1">Send as text message (title is not included)</label>
          </div>
          <span class="errormsg" id="message_error">{{errorMsg}}</span>
				</div>
      </div>
		  <div class="col-md-12"> 
        <input type="button" class="btn btn-dark mr-4" id="send" (click)="sendMessage()" value="Send">  
        <input type="button" class="btn btn-outline-dark" id="sendToList" *ngIf="type==2" (click)="addToMyList()" value="Save to my list">
        <p>&nbsp;</p>
      </div><!-- END ROW -->
    </div><!-- END ROW -->
    
    