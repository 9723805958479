import { Component, OnInit, AfterViewInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
 } from '@angular/core';
 import {UserModel} from '../model/user.model';
 import {FormsModule} from '@angular/forms';
 import {User, Office} from '../model/dto'
import {ApiService} from '../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
   @ViewChild('cardInfo') cardInfo: ElementRef;
	private fields:string[] = ['email', 'name'];
  subscription:any;
  public PkUser:number
  public office:Office;
  public user:User;
  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;
  public gralError:string;
  public email:string;
  public name:string;
  private cardToken:string;
  private amount:number = 1200;
  private pass:string;
  constructor(private _api:ApiService, private cd:ChangeDetectorRef, 
    private route:ActivatedRoute, private _router:Router, private _userModel:UserModel) { }

  ngOnInit() {
     this.subscription = this.route.params.subscribe(params => {
         this.PkUser = Number.parseInt(params['id']);
         if(!isNaN(this.PkUser))
           this._api.getOfficeByUser(this.PkUser)
              .subscribe(result => this.onOffice(result));
       });
     //get password, is needed for send to client
     this.pass = this._userModel.tmpPass;
  }

   ngAfterViewInit(){
    this.hideErrors();
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
    //$('#payment4').prop('checked', 'checked');
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  onOffice(data:object){
    this.office = data as Office;
    this.office.Image = this._api.domain+'images/proffesionals/'+this.office.Image;
    this.user = this.office.user;
    this.email = this.user.email;
    this.name = this.office.Contact
  }

  validate(){
    this.hideErrors();
    var error:boolean = false;
    if(!this.validateEmail(this.email)) { error=true; $('#email_error').show()}
    if(this.name.length<3){ error=true; $('#name_error').show()}    
    this.amount = $("input[name='payment']:checked").val();
    if(!error) this.onSubmit();
  }

  async onSubmit() {
       const { token, error } = await stripe.createToken(this.card, {email: this.email});

      if (error) {
        console.log('Something is wrong:', error);
        this.gralError = 'There is some problem: '+error['message'];
      } else {
       // console.log('Success!', token);
        this.cardToken = token.id
        /*var data = {token:this.cardToken, PkUser:this.PkUser,amount:this.amount, description:"Monthly subscription to Transforma Connect"}
        this._api.makePayment(data)
          .subscribe(result => this.onPayment(result));*/
          this.getTokenForSubscription();
      }
  }

  //get a new token for subscription
    async getTokenForSubscription(){
      const { token, error } = await stripe.createToken(this.card, {email: this.email});

      if (error) {
        //console.log('Something is wrong:', error);
        this.gralError = 'There was some problem.'+error;
      } else {
       // console.log('Success!', token);
        this.cardToken = token.id
        this._api.createSubscription(this.PkUser, this.cardToken, this.amount, this.pass) 
              .subscribe(result => this.onFinish(result));
      }
    }

     onFinish(data:object){
       if(data['success']==false){
         alert(data['data']['error']['message'])
       } else
       this._router.navigate(['/paymentconfirm/'+this.PkUser])
    }

   hideErrors(){
  	for (var i = 0; i < this.fields.length; i++) $('#'+this.fields[i]+'_error').hide();
  	//this.generalError = '';
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
