import { Injectable, Component } from '@angular/core';
import { Http, Response, Headers, RequestOptions, URLSearchParams, ResponseContentType } from '@angular/http';
import { Observable, Subject, asapScheduler, pipe,  from, interval, merge, fromEvent } from 'rxjs';
import 'rxjs/add/observable/of';
import { map, filter, scan, catchError, mergeMap } from 'rxjs/operators';
import { BaseService } from '../services/base.service';
import { User, Page, Proffesional, Specialty, Office, Payment} from '../model/dto';

@Injectable()
export class ApiService extends BaseService{

  constructor(){
    super();
  }
  
  getToken(email:string, pass:string){
    let body = JSON.stringify({email:email,password:pass});
    return this._httpClient.post(this.domain+'login', body).pipe(
        map((response: Response) => <Object>response.json()),
        catchError(this.handleError)
      ); 
  }

  public loginUser(email:string, pass:string, skipPayment:boolean = false){
    let body = JSON.stringify({email:email,password:pass, skip:skipPayment});
    return this._httpClient.post(this.domain+'api/loginuser', body).pipe(
      map((response: Response) => response.json().data),
      catchError(this.handleError)
     ); 
  }

  public getProffesionals(page:number = 1 ){
    return this._httpClient.get(this.domain+'api/proffesional?page='+page).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }

  public getListMembers(listId){
   return this._httpClient.get(this.domain+'api/getlistmembers/'+listId).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     ); 
  }

  public createList(name:string, PkUser:number){
     let body = JSON.stringify({Name:name, PkUser:PkUser});
      return this._httpClient.post(this.domain+'api/lists', body).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }
  public deleteList(PkList:number){
    return this._httpClient.get(this.domain+'api/lists/'+PkList).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }

  public deleteMsg(id:number, from:boolean){
    var method = (from)?"hidemsgfrom":"hidemsgto";
    return this._httpClient.get(this.domain+'api/'+method+'/'+id).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }

  public getList(id:number, page:number){
     return this._httpClient.get(this.domain+'api/getlistmembers/'+id+'?page='+page).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }

  public addToDaylite(PkProffesional:number){
    return this._httpClient.get(this.domain+'api/addtodaylite/'+PkProffesional).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }

  public profSearchList(keyword:string, id:number){
     return this._httpClient.get(this.domain+'api/gralprofsearchlist?keyword='+keyword+'&id='+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  public saveToList(PkList:number, Prof:number[], Name:string = null, userId:number = 0){
    let body = JSON.stringify({FkList:PkList, FkProffesional:Prof, Name:Name, userId:userId});
      return this._httpClient.post(this.domain+'api/addtolist', body).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }  

  public deleteFromList(PkList:number, Prof:number[]){
    let body = JSON.stringify({FkList:PkList, FkProffesional:Prof});
      return this._httpClient.post(this.domain+'api/removefromlist', body).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }

  public removeFromList(PkList:number, prof){
    let body = JSON.stringify({FkList:PkList, FkProffesional:prof});
      return this._httpClient.post(this.domain+'api/removefromlist', body).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }

  public getLists(id:number){
     return this._httpClient.get(this.domain+'api/getmyadminlists/'+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }

  public getProffesionalsByLetter(letter:string ){
    return this._httpClient.get(this.domain+'api/proffesionalbyletter?letter='+letter).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }

  public deleteProffesional(id:number, force:boolean = false){
    var url:string = (force)?'api/fproffesional/':'api/proffesional/';
    return this._httpClient.delete(this.domain+url+id).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }

  public restoreProffesional(id:number){
    return this._httpClient.get(this.domain+'api/restoreproffesional?id='+id).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }

  public deletePdf(PkFile){
     return this._httpClient.get(this.domain+'api/deletepdf/'+PkFile).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
     );
  }
  public getSpecialties(){
    return this._httpClient.get(this.domain+'specialty').pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }

  public getLanguages(){
    return this._httpClient.get(this.domain+'language').pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
     );
  }

  public addProfLanguages(data:object[]){
    let body = JSON.stringify({languages:data});
    return this._httpClient.post(this.domain + 'addproflanguage', body).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
      ); 
  }

  public filterProf(filter:object){
    let body = JSON.stringify(filter);
    return this._httpClient.post(this.domain + 'api/getfilteredprof', body).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
      ); 
  }

  public profSearch(keyword:string){
     return this._httpClient.get(this.domain+'api/gralprofsearch?keyword='+keyword).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

    public createUser(data: User) {
        
        var skip = ['updated_at', 'Rol', 'password', 'deleted_at', 'proffesional', 'office','file']
        var info = {}
        for(var prop in data) if(skip.indexOf(prop)==-1){
          info[prop] = data[prop]
          //console.log('Agrego '+prop)
        } 
        if(data.password!='********') info['password'] = data.password;
        let body = JSON.stringify(info);

        if (data.PkUser && data.PkUser > 0) { // UPDATE
            return this._httpClient.put(this.domain + 'api/users/'+data.PkUser, body).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
               );
        } else { // CREA
            return this._httpClient.post(this.domain + 'users', body).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
              );
        }
    }

    unsubscribe(PkUser:number, reason:string){
      var info = {id:PkUser, reason:reason}
      let body = JSON.stringify(info);
      return this._httpClient.post(this.domain + 'api/unsubscribe', body).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
              );
    }

    public unsubscribeoffice(PkUser:number, reason:string){
      var info = {id:PkUser, reason:reason}
      let body = JSON.stringify(info);
      return this._httpClient.post(this.domain + 'api/unsubscribeoffice', body).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
              );
    }

    public cancelSubscription(PkUser:number){
      return this._httpClient.get(this.domain+'api/cancelsubscription/'+PkUser).pipe(
        map((response: Response) => <Object>response.json()),
        catchError(this.handleError)
      ); 
    }

    sendPassword(email:string){
      return this._httpClient.get(this.domain+'sendpassword?email='+email).pipe(
        map((response: Response) => <Object>response.json()),
        catchError(this.handleError)
      ); 
    }

    createSpecialties(PkProf:number, specialties:Specialty[]) {
        let body = JSON.stringify({specs:specialties})
         return this._httpClient.post(this.domain + 'updatemanyspecs/'+PkProf, body).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
              );
     }

     public createProffesional(data:Proffesional, file:File, pdf:FileList) {
        var skip = ['Image', 'Pdf', 'specialty','files', 'Date', 'user', 'language'];
         let formData = new FormData();
        formData.append('Image', file);
        if(pdf && pdf.length) //if there are files
          for(var i:number = 0; i<pdf.length; i++){
            formData.append('file'+i, pdf[i]);
          }
        formData.append('qfiles', (pdf && pdf.length)?pdf.length.toString():'0');
        for (var prop in data) {
            if(skip.indexOf(prop)==-1) formData.append(prop, data[prop])
        }
        if (data.PkProffesional != 0) { // UPDATE
            return this._httpClient.postFile(this.domain + 'api/updateproffesional/'+data.PkProffesional, formData).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
               );
        } else { // CREA
            return this._httpClient.postFile(this.domain + 'proffesional', formData).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
              );
        }
    }

  public saveImage(data:string, name:string){
    let body =  JSON.stringify({data:data, name:name});
    return this._httpClient.post(this.domain +'api/editimage', body).pipe(                
      catchError(this.handleError)
    );
  }

  public convertToDate(date:string, hour:boolean = false):string{
    var str:string = date.substr(5,2)+'/'+date.substr(8,2)+'/'+date.substr(0,4);
    if(hour) str+=' '+date.substr(11,2)+':'+date.substr(14,2)+':'+date.substr(17,2);
    return str;
  }

   convertDateToString(fecha:Date):string{
    var month = ((fecha.getMonth()+1)<10)?'0'+(fecha.getMonth()+1):fecha.getMonth()+1;
    var day = (fecha.getDate()<10)?'0'+fecha.getDate():fecha.getDate();
    var hour = (fecha.getHours()<10)?'0'+fecha.getHours():fecha.getHours();
    var minutes = (fecha.getMinutes()<10)?'0'+fecha.getMinutes():fecha.getMinutes();
    return fecha.getFullYear()+'-'+month+'-'+day+' '+hour+':'+minutes+':00';
  }

  public getProffesionalByUser(id:number){
     return this._httpClient.get(this.domain+'api/getprofbyuser?id='+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  public getProfessional(id:number){
     return this._httpClient.get(this.domain+'api/proffesional/'+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  getOffices(page:number){
     return this._httpClient.get(this.domain+'api/office').pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
    ); 
  }

  getOfficeByUser(id:number){
     return this._httpClient.get(this.domain+'api/getofficebyuser?id='+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  public officeSearch(keyword:string){
     return this._httpClient.get(this.domain+'api/gralofficesearch?keyword='+keyword).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

   public createOffice(data:Office, file:File, FkInstitution:number = null) {
        var skip = ['Image', 'user'];
         let formData = new FormData();
        formData.append('Image', file);
        for (var prop in data) {
            if(skip.indexOf(prop)==-1) formData.append(prop, data[prop])
        }     
        if (data.PkOffice != 0) { // UPDATE
            if(FkInstitution) formData.append('FkInstitution', FkInstitution.toString());
            return this._httpClient.postFile(this.domain + 'api/updateoffice/'+data.PkOffice, formData).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
               );
        } else { // CREA
            return this._httpClient.postFile(this.domain + 'office', formData).pipe(
                map((res: Response) => res.json()),
                catchError(this.handleError)
              );
        }
    }

    getOffice(id:number){
     return this._httpClient.get(this.domain+'api/office/'+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  getInstitutions(){
    return this._httpClient.get(this.domain+'api/institution').pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

   fileExists(url:string){
    return this._http.head(url).pipe(
       map((response) => response.status),
       catchError(()=>{return '0'})
    ); 
  }
  getSender(PkUser:number){
      return this._httpClient.get(this.domain+'api/getuserwithdetail/'+PkUser).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  getMessages(page:number, userId:number){
     return this._httpClient.get(this.domain+'api/getmsgfor?PkUser='+userId).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
  )}

  getUnreadMessages(userId:number){
    return this._httpClient.get(this.domain+'api/getunread/'+userId).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
  )}

  getMessage(id:number){
     return this._httpClient.get(this.domain+'api/messages/'+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  markAsView(id:number){
      return this._httpClient.get(this.domain+'api/markmessage/'+id).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

  sendMessage(from:number, to:number, title:string, msg:string, sendSms){
    let body = JSON.stringify({FkUser:from,ToUser:to, Title:title, Message:msg, SMS:(sendSms=="true")?1:0})
      return this._httpClient.post(this.domain + 'api/messages', body).pipe(
        map((res: Response) => res.json()),
        catchError(this.handleError)
      );
  }

  //sends HTML
  sendMassMail(PkList:number, msg:string, subject:string){
    let body = JSON.stringify({id:PkList,content:msg, title:subject})
     return this._httpClient.post(this.domain + 'api/massmessage', body).pipe(
            map((res: Response) => res.json()),
            catchError(this.handleError)
          );
  }

  sendMassSms(PkList:number, msg:string, subject:string){
    let body = JSON.stringify({id:PkList,content:msg, title:subject})
     return this._httpClient.post(this.domain + 'api/smsbulkmessage', body).pipe(
            map((res: Response) => res.json()),
            catchError(this.handleError)
          );
  }
  

  addToMyList(PkUser:number, PkOwner:number){
     let body = JSON.stringify({FkUser:PkUser,FkOwner:PkOwner})
         return this._httpClient.post(this.domain + 'api/mylist', body).pipe(
            map((res: Response) => res.json()),
            catchError(this.handleError)
          );
  }

  getMyList(PkUser:number){
     return this._httpClient.get(this.domain+'api/getmyadminlists/'+PkUser).pipe(
      map((response: Response) => <Object>response.json().data),
      catchError(this.handleError)
    ); 
  }

   makePayment(data:object){
     let body = JSON.stringify(data);
      return this._httpClient.post(this.domain+'api/paycard', body).pipe(
        map((response: Response) => response.json()),
        catchError(this.handleError)
       ); 
  }

   createSubscription(PkMember:number, token:string, amount:number, pass:string){
     var data = {PkUser:PkMember, token: token, amount: amount, password: pass};
     var body = JSON.stringify(data);
     return this._httpClient.post(this.domain+'api/subscribe', body).pipe(
        map((response: Response) => response.json()),
        catchError(this.handleError)
       ); 
  }

  getPayment(PkUser:number){
     return this._httpClient.get(this.domain+'api/getpaymentwithuser?PkUser='+PkUser).pipe(
        map((response: Response) => response.json()),
        catchError(this.handleError)
       ); 
  }

  getStripeToken(code:string){
     return this._httpClient.get(this.domain + 'api/gettoken?code='+code).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
      ); 
  }

  getStripeId(){
    return this._httpClient.get(this.domain + 'api/getstripeid').pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
      ); 
  }


  setTwilioId(code:string){
     return this._httpClient.get(this.domain + 'api/smsconnect?code='+code).pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
      ); 
  }

  getTwilioId(){
    return this._httpClient.get(this.domain + 'api/twilioid').pipe(
      map((response: Response) => <Object>response.json()),
      catchError(this.handleError)
      ); 
  }
  /*****************************
  * Helper functions
  ******************************/

  public emailIsValid (email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  public convertDBtoDate(data:string):Date{
    //2019-06-28 09:17:19
    var year = parseInt(data.substr(0,4));
    var month = parseInt(data.substr(5,2));
    var day = parseInt(data.substr(8,2));
    return new Date(year,month,day);
  }
  
  // a and b are javascript Date objects
  public dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
}
