import { Component, OnInit } from '@angular/core';
import { BaseModel } from '../model/base.model';
import { UserModel } from '../model/user.model';
import {User} from '../model/dto';
import { ApiService} from '../services/api.service';
import {Router} from '@angular/router';
import { FormsModule } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  public recovery = false;
  public email:string = '';
    public password:string = '';
    public loginerror:string = ''
    public showLogo:number=1;
    public askforpassword:string;
    public label:string = "Sign in";
    public isMobile:boolean = false;

    constructor(
          private _baseModel:BaseModel, 
          private userModel:UserModel, 
  		    private _api:ApiService, 
          private router:Router) {
    }

  ngOnInit() { 
    this.isMobile = ($(window).width()<600);
  }

  public loginUser(){
    if(this.email.length<3) return this.loginerror = 'Email is mandatory';
    if(this.label=="Send password") return this.sendPassword()
    if(this.password.length<3) return this.loginerror = 'Password is mandatory';
    this._api.getToken(this.email, this.password)
      .subscribe(result=>this.onToken(result))
  }

  sendPassword(){
    this._api.sendPassword(this.email)
      .subscribe(result=>this.onPassword(result))
  }

  onPassword(data:object){
    //console.log(data);
    if(data['status']=='error') this.askforpassword = data['message'];
    else this.askforpassword = 'Password sent. Please reload the form to login';
  }

  onToken(data:object){
    var token:string = data["token"];
    if(!token) {
      this.loginerror = "Wrong credentials";
    }  else { //tengo el token, me logueo
       localStorage.setItem('access_token', token);
       this._api.loginUser(this.email, this.password)
        .subscribe(result=>this.onLogin(result))
    }
  }

  private onLogin(data:object){   
    this.userModel.user = data as User;    
    this._baseModel.setProp({name:'login', value:1})
    if(data['status']=="error") return this.loginerror = data['msg'];
    this.loginerror = "";
    if(this.userModel.user.FkType==1) 
        this.router.navigate(['prodetail/'+this.userModel.user['proffesional'].PkProffesional]);
    else 
      this.router.navigate(['listprof']);
  }
  
  public askForPass(){
    this.recovery = true;
    this.askforpassword = "Please enter the account email";
    this.label = "Send password";
    this.loginerror = '';
  }

}