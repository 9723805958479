<app-navigation></app-navigation> 

<app-title-box title="&nbsp;"></app-title-box>

<div class="wrapper wrapper_lg air air_horz">
    <div class="row">
    	<div class="col-lg-2 l-white">
			<br><br><br><br>
			<div class="d-flex justify-content-end">
    			<img class="officeImg" src="{{office.Image}}" class="officeImg">  
    		</div>
    	</div>
        <div class="col-lg-5 l-white">  
            <div class="login ml-lg-10 mt-lg-10">
                <div class="flex-column">
                	<div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
                	<div class="row mt-8"><!-- ROW -->
	                    <div class="container">
	                     <span class="h3">{{office.Name}}</span>
	                     <span>&nbsp;&nbsp;<a *ngIf="isAdmin" class="btn-outline-secondary" href="officeform/{{office.FkUser}}">Edit</a></span>
	                    </div>
	                    <div class="col-3">&nbsp;</div>
	                </div>
	                <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
                    <div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
                       		<p class="mt-2">
                       			<span class="text-bold">Office Name</span><br>
                       			<span class="h5">{{office.Name}}</span>	
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary border-top-0">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Services</span><br>
	                       		<span class="h5">{{office.Services}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary border-top-0">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Year established</span><br>
	                       		<span class="h5">{{office.Years}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
						

	                <div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
                       		<p class="mt-2">
                       			<span class="text-bold">Contact Name</span><br>
                       			<span class="h5">{{office.Contact}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary border-top-0">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Address</span><br>
	                       		<span class="h5">{{office.Address}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary border-top-0">
	                    	<p class="mt-2">
	                       		<span class="text-bold">City</span><br>
	                       		<span class="h5">{{office.City}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary border-top-0">
	                    	<p class="mt-2">
	                       		<span class="text-bold">State</span><br>
	                       		<span class="h5">{{office.State}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary border-top-0">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Phone</span><br>
	                       		<span class="h5">{{office.Telephone}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary border-top-0">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Email</span><br>
	                       		<span class="h5">{{office.Email}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
                </div>
            </div>
        </div>
        <div class="flex-column col-lg-5 l-white">
        	<app-send-message *ngIf="sendMessageOffices" type="{{user.FkType}}"></app-send-message>
        <div><!-- end side column -->
    </div>
</div>
<app-footer></app-footer>