	 <footer class="footer text-white">
	    <div class="d-flex row justify-content-center">
		    <small class="text-muted ml-2">TransForma LLC 2019 V2.8</small>	    
		    <!--<small *ngIf="isForm"><a href="mailto:admin@transforma.us" class="text-muted">Contact TransForma at admin@transforma.us</a></small>-->
		    <span *ngIf="!isMobile">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
		    <small *ngIf="isForm" class
		   ="text-muted"><a href="javascript:void(0)" class="text-muted" data-toggle="modal" data-target="#unsubscribe">Unsubscribe</a></small>
		   <!-- <small *ngIf="!isForm"><a href="mailto:admin@transforma.us" class="text-muted">Contact TransForma at admin@transforma.us</a></small>-->	   
		</div>  
	</footer>
<img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" class="d-none" onload="onLoadDocument()">