<app-navigation></app-navigation> 

<app-title-box title="&nbsp;"></app-title-box>

<div class="wrapper wrapper_lg air air_horz">
    <div class="row">
    	<div class="col-lg-2 l-white">			
			<div class="d-flex justify-content-end">
				<a href="javascript:void(0)" (click)="showFullImage()">
    				<img *ngIf="profImage.length>4" src="{{profImage}}" class="profileImg">  
    			</a>
    		</div>
    	</div>
        <div class="col-lg-5 l-white" *ngIf="proffesional">  
            <div class="login ml-lg-10 mt-lg-10">
                <div class="flex-column justify-content-center">
                	<div class="row mt-8">
                		<div class="col-12">
                			&nbsp;  
                		</div>
                	</div> <!-- separa -->
                	<div class="row mt-8"><!-- ROW -->
	                    <div class="container">
	                     <span class="h3">&nbsp;{{this.proffesional.FirstName}} {{this.proffesional.LastName}}</span>&nbsp;&nbsp;<small class="errormsg" *ngIf="hideProfile">Profile hidden from searches</small>
	                     <a *ngIf="canEdit" class="btn-outline-secondary" href="proform/{{this.proffesional.FkUser}}">Edit</a>
	                    </div>
	                    <div class="col-3">&nbsp;</div>
	                </div>
	                <div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
                    <div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
                       		<p class="mt-2">
                       			<span class="text-bold">Name</span><br>
                       			<span class="h5">{{proffesional.FirstName}} {{proffesional.Middle}} {{proffesional.LastName}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Specialty</span><br>
	                       		<span class="h5" *ngFor="let item of selSpecialties">{{item.Name}}&nbsp;&nbsp;
								<small class="text-muted" *ngIf="item.License">#{{item.License}} - {{item.Valid | date:'MM/dd/yyyy'}}</small><br>
	                       		</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Hourly Rate</span><br>
	                       		<span class="h5">$ {{proffesional.HourlyRate}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Years of Experience</span><br>
	                       		<span class="h5" *ngIf="years==1">{{years}} year</span>
	                       		<span class="h5" *ngIf="years>1">{{years}} years</span>
	                       		<span class="h5" *ngIf="years<=0">Less than a year</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->	
					<div class="row mt-8 ml-1 mr-1" *ngIf="proffesional.language.length"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Languages</span><br>
	                       		<span class="h5">{{lang}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->	
					<div class="row mt-8 ml-1 mr-1"  *ngIf="daysSelected.length"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Days Available</span><br>
	                       		<span class="h5">{{daysSelected.toString()}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->					
					<div class="row mt-8 ml-1 mr-1"  *ngIf="proffesional.Position!=''"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Employment Terms</span><br>
	                       		<span class="h5" *ngIf="proffesional.Position=='p'">Permanent</span>
	                       		<span class="h5" *ngIf="proffesional.Position=='t'">Temporary</span>
	                       		<span class="h5" *ngIf="proffesional.Position=='b'">Permanent or Temporary</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->										
					<div class="row mt-8 ml-1 mr-1" *ngIf="proffesional.Pdf"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Download CV</span><br>
	                       		<span class="h5"><a href="{{proffesional.Pdf}}" target="_new">Download CV</a></span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8"><div class="col-12">&nbsp;</div></div> <!-- separa -->
						
					<div class="row mt-8"><!-- ROW -->
	                    <div class="container">
	                     <span class="h3">Contact Info</span>
	                    </div>
	                    <div class="col-3">&nbsp;</div>
	                </div>
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">City</span><br>
	                       		<span class="h5">{{proffesional.City}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">State</span><br>
	                       		<span class="h5">{{proffesional.State}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Address</span><br>
	                       		<span class="h5">{{proffesional.Address}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Zip</span><br>
	                       		<span class="h5">{{proffesional.Zip}}</span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Phone</span><br>
	                       		<span class="h5"><a [href]="'tel:'+proffesional.Mobile">{{proffesional.Mobile | phone}}</a></span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 ml-1 mr-1"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<p class="mt-2">
	                       		<span class="text-bold">Email</span><br>
	                       		<span class="h5" *ngIf="proffesional.user"><a [href]="'mailto:'+proffesional.user.email">{{proffesional.user.email}}</a></span>
                       		</p>
	                    </div>
	                    <div class="col-md-3">&nbsp;</div> 
					</div><!-- END ROW -->
					<div class="row mt-8 mb-4 ml-1 mr-1" *ngIf="proffesional.files && proffesional.files.length>0"><!-- ROW -->                    
	                    <div class="col-md-9 border border-secondary">
	                    	<span class="text-bold">Documents</span><br>
	                    	<p class="mt-2" *ngFor="let item of proffesional.files">
	                       		<span><a href="../php/readfile.php?file={{proffesional.PkProffesional}}/{{item.Name}}">{{item.Name}}</a></span>                      		
                       		</p>
	                    </div>	                    
					</div><!-- END ROW -->
					<div class="row mb-4 ml-1 mr-1"></div><!-- ROW -->    					
                </div>
            </div>
        </div>        
        <div class="flex-column col-lg-5 l-white">       	
        	<app-send-message *ngIf="user.FkType>1" (broadcast)="handleAction($event)" type="{{user.FkType}}"></app-send-message>  	
        <div> <!-- end side column -->
    </div>	
</div>
<app-footer></app-footer>

<ng-template #fullImage let-modal>
      <div class="modal-header">       
        <button type="button" class="close" (click)="auxModal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body"> 
      	<div class="d-flex row justify-content-center">       
        	<img src="{{proffesional.Image}}">
        </div>
      </div>      
</ng-template>

<div class="modal" tabindex="-1" role="dialog" id="confirma">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Proffesional added</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{status}}</p>
        <p>Please go to my list to see all proffesionals saved.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<div class="modal" tabindex="-1" role="dialog" id="msg">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Message sent</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{status}}</p>
        <p>Please go to messages to see all.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>