import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { UserModel } from '../model/user.model'
import { BaseModel } from '../model/base.model'
import { User } from '../model/dto'
import { ApiService } from '../services/api.service';
import {SimpleTimer} from 'ng2-simple-timer';
import * as $ from 'jquery';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  inputs:['showNav']
})
export class NavigationComponent implements OnInit, OnDestroy {

  public user: User;
  public menuIsCollapsed:boolean = true;
  public showNav:boolean = true;
  public unreadMsg:number = 0;
  private userId:number;
  subscription:any

  constructor(
    private _api: ApiService,
    private router: Router,
    private _baseModel:BaseModel,
    public userModel: UserModel,
    private st:SimpleTimer
  ) {
    this.subscription = this._baseModel.docItem$.subscribe(
        item => this.changed(item)
      );
  }

  ngOnInit() {
    this.st.delTimer('minute');
    this.user = this.userModel.user
    this.userId = this.user.PkUser;
    this.getUnreadMessages();
     if(this.st.getTimer()[0]!='minute'){
       this.st.newTimer('3 minute', 180, true);
       var status = this.st.subscribe('minute', () => this.getUnreadMessages());  
     }
     this.menuIsCollapsed = false;
     this.makeRoom()
  }

  ngAfterViewInit(){
       setTimeout(function(w){
        w.menuIsCollapsed = true;
        w.makeRoom()
      }, 1500, this) 
  }

  getUnreadMessages(){
    this._api.getUnreadMessages(this.user.PkUser)
      .subscribe(result=>this.onUnread(result))
  }

  changed(evt:object){
    var key:string = evt["name"];
    let value:any = evt["value"];
    if(key=="read") {
      this._api.getUnreadMessages(this.userId)
        .subscribe(result=>this.onUnread(result))
    }
  }

  onUnread(data){
    this.unreadMsg = parseInt(data);
  }

  logout () {
    window.localStorage.clear()
    this.router.navigate(['/'])
  }

  makeRoom(){    
    $(window).scrollTop(0);
    $('#top').css("padding-top", (this.menuIsCollapsed)?120:300);
  }

   ngOnDestroy(){
      this.st.unsubscribe('minute');
      this.st.delTimer('minute');
    this.subscription.unsubscribe();
  }
}
