import { Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Office } from '../model/dto';
import { Router } from '@angular/router';
import {BaseModel} from '../model/base.model';
import { TruncateModule } from '@yellowspot/ng-truncate';

@Component({
  selector: 'app-officelist',
  templateUrl: './office-list.component.html',
  styleUrls: ['./office-list.component.css'] 
})
export class OfficeListComponent implements OnInit {

  public offices:Office[];
  public pages:number[];
  public currentPage:number = 1;
  public total:number = 1;
  public filterName:string = 'Select filter';
  public filterValue:string = '';
  public isCollapsed:boolean = true;
  public isMAsearchCollapsed:boolean = true;
  subscription:any;

  constructor(private _api:ApiService, private _baseModel:BaseModel) { 
    this.subscription = this._baseModel.docItem$.subscribe(
        item => this.changed(item)
    );
  }

  ngOnInit() { 
  	this._api.getOffices(1)
  		.subscribe(result => this.onOffices(result)); 
  }

  onOffices(data:object){
  	this.offices = [];
  	this.total = parseInt(data['last_page']);
  	this.currentPage = parseInt(data['current_page']);
  	this.pages = [];
  	for (var i = 0; i<this.total; i++) this.pages.push(i+1);
  	for (i = 0; i < data['data']['length']; i++) {
  		var ofi:Office = data['data'][i] as Office;
  		this.offices.push(ofi);
  	}
  }

   loadPage(page:number){
  		this._api.getOffices(page)
  			.subscribe(result => this.onOffices(result));
  }

   changed(item:Object){
    //console.log("Evt "+item['name']);//+":"+item['value'])
    //for(var prop in item['value']) console.log(prop+": "+item['value'][prop])
    let key:string = item["name"];
    let value:any = item["value"];
    if(key=="searchOffice"){
        this._api.officeSearch(value)
         .subscribe(result => this.onOffices({data:result}))  
    }
  }
}