import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Message, User, Proffesional } from '../model/dto';
import {UserModel} from '../model/user.model';
import { Router } from '@angular/router';
import {BaseModel} from '../model/base.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.css']
})
export class MessageListComponent implements OnInit {
  @ViewChild('t') tabset;

  public received:Message[];
  public sent:Message[];
  public pages:number[];
  public currentPage:number = 1;
  public total:number = 1;
  public filterName:string = 'Select filter';
  public filterValue:string = '';
  public isCollapsed:boolean = true;
  public isMAsearchCollapsed:boolean = true;
  public user:User;
  public adminLists:object[];
  public selLista:number = 0;
  public ckeditorContent:string;
  public subject:string;
  public proffesionals:Proffesional[];
  public specialties:object;
  public profToDel:number[];
  protected checkAllBoxes:boolean = false;
  public byMail:boolean = true;
  public bySMS:boolean = false;
  public newList:string;
  public newListMobile:string;
  public listError:string = "";
  public isMobile:boolean
  constructor(private _api:ApiService, private _userModel:UserModel, private router: Router) {
    this.user = this._userModel.user;
   }

  ngOnInit() {
  	this._api.getMessages(1, this.user.PkUser)
  		.subscribe(result => this.onMessages(result)); 
     this._api.getLists(this.user.PkUser)
        .subscribe(result=>this.onLists(result))
     this._api.getSpecialties()
      .subscribe(result => this.onSpecialties(result));

    if(window){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) this.isMobile = true;    
    } else {
      this.isMobile = true;
    }  
  }

  ngAfterViewInit(){
    var url = this.router.url
    var tmp = url.split('/');
    if(tmp[tmp.length-1]=='list'){
      setTimeout(function(w){
         w.tabset.select('Lists');
      }, 1000, this);
      
    }
  }

  tabChange(event){
      //console.log('change')
   }

  onLists(data:object){
    this.adminLists = []
    var tot = data['length']
    var cant:number = 0;
    for (var i = 0; i < tot; i++) {
     cant = data[i]['members']['length']
     this.adminLists.push({label:data[i].Name+'('+cant+')', value:data[i].PkList});
    }
    if(cant==0) this.listError = "No lists yet, use create button"
  }

   onSpecialties(data:object){
    this.specialties = {};
    for(var i:number = 0; i<data['length']; i++){
      this.specialties[data[i]['PkSpecialty']] = data[i]['Name'];
    }   
  }

  onListSelect(){
    this.newList = '';
    this._api.getListMembers(this.selLista)
      .subscribe(result=>this.onProffesionals(result))
  }

  onProffesionals(data:object){
    this.proffesionals = [];
    this.total = parseInt(data['last_page']);
    this.currentPage = parseInt(data['current_page']);
    this.pages = [];
    var item:string;
    var proffesional:Proffesional
    for (var i = 0; i<this.total; i++) this.pages.push(i+1);
     // console.log('Encuentra '+ data['data']['length']);
    for (i = 0; i < data['data']['length']; i++) {
      proffesional = data['data'][i] as Proffesional;
      proffesional.SpecialtyName = '';
      for (var z = 0; z < proffesional.specialty.length; z++) {
         item = this.specialties[proffesional.specialty[z]['FkSpecialty']] as string;
         proffesional.SpecialtyName += item+', '         
      }
     proffesional.FullAddress = proffesional.City+' '+proffesional.Zip+', '+proffesional.State      
     proffesional.Image = this._api.domain+'images/proffesionals/pics/'+proffesional.Image;
     proffesional.SpecialtyName = proffesional.SpecialtyName.substr(0, proffesional.SpecialtyName.length-2);
      this.proffesionals.push(proffesional);
    }
  }

  onMessages(data:object){
  	this.received = [];
    this.sent = [];
  	for (var i = 0; i < data['from']['length']; i++) {
  		var msg:Message = data['from'][i] as Message;
      //console.log(i+'.- '+msg.PkMessage)
      if(msg.touser && msg.touser.FkType==1){
        var name:string = msg.touser['proffesional']['FirstName']+' '+msg.touser['proffesional']['LastName']       
        msg.Label = "To: "+name
        msg.tolink = "/prodetail/"+msg.touser['proffesional']['PkProffesional'];
      } else if(msg.touser && msg.touser.FkType==2){
        var name:string = msg.touser['office']['Name'];
        msg.Label = "To: "+name
        msg.tolink = "/officedetail/"+msg.touser['office']['PkOffice'];
      }
  		this.sent.push(msg);
  	}
   
    for (var i = 0; i < data['to']['length']; i++) {
      var msg:Message = data['to'][i] as Message;
      if(msg.fromuser && msg.fromuser.FkType==1){
        var name:string = msg.fromuser['proffesional']['FirstName']+' '+msg.fromuser['proffesional']['LastName']       
        msg.Label = 'From: '+name;
        msg.fromlink = "/prodetail/"+msg.fromuser['proffesional']['PkProffesional'];
      } else if(msg.fromuser && msg.fromuser.FkType==2){
        var name:string = msg.fromuser['office']['Name'];
        msg.Label = 'From: '+name;
        msg.fromlink = "/officedetail/"+msg.fromuser['office']['PkOffice'];
      }
      this.received.push(msg);
    }
  }

   loadPage(page:number){
  		this._api.getMessages(page, this.user.PkUser)
  			.subscribe(result => this.onMessages(result));
  }

  createList(isMobile:boolean){
    if(this.newListMobile==undefined || this.newListMobile.length<3){
      this.listError = "List name is mandatory"
    } else {
      this.listError = "";
      this._api.createList(this.newListMobile, this.user.PkUser)
        .subscribe(result=>this.onCreated(result))
    }
    this.newList = "";
    this.newListMobile = "";
  }

  deleteList(){
    if(!this.selLista || this.selLista==0) this.listError = "Should select a list to delete"
    if(confirm('You really want to delete list '+this.getListName(this.selLista)+'?')){
      this.listError = "";
      this._api.deleteList(this.selLista)
        .subscribe(result=>this.onDeleteList(result))
    }
  }
  
  deleteMsg(id:number, isSent:boolean){
    if(confirm('You really want to delete this message')){
      this._api.deleteMsg(id, isSent)
        .subscribe(result=>this.onDeleteMsg(result, id, isSent))
    }
  }

  private getListName(id){
    return this.adminLists.filter(item => item['value'] == id)[0]['label'].split('(')[0];
  }
  onDeleteList(data){
    for(var i:number=0; i<this.adminLists.length; i++){
      if(this.adminLists[i]['value'] == this.selLista){
        this.adminLists.splice(i, 1);
        this.selLista = 0;
        this.proffesionals = [];
        break;
      }
    }
  }

  onDeleteMsg(data:object, id:number, isSent:boolean){
    if(isSent){
      for (var i = this.sent.length - 1; i >= 0; i--) {
        if(this.sent[i].PkMessage == id){
          this.sent.splice(i, 1);
          break;
        }
      }
    } else {
      for (var i = this.received.length - 1; i >= 0; i--) {
        if(this.received[i].PkMessage == id){
          this.received.splice(i, 1);
          break;
        }
      }
    }
  }

  onCreated(data:object){
    this.adminLists.push({label:data['Name']+'(0)', value:data['PkList']})
  }
  
  checkAll(){
    this.checkAllBoxes = !this.checkAllBoxes;
    var tot:number = this.proffesionals.length
    for (var i = 0; i < tot; i++) {
      $('#'+this.proffesionals[i].PkProffesional).prop('checked', this.checkAllBoxes);
    }
  }

  public sendContent(){    
    if(!this.byMail && !this.bySMS) return alert('Select email and/or text for message sending'); 
   if(this.byMail)
      this._api.sendMassMail(this.selLista, this.ckeditorContent, this.subject)
        .subscribe(result=>this.onMailing(result))
    if(this.bySMS)
       this._api.sendMassSms(this.selLista, this.ckeditorContent, this.subject)
        .subscribe(result=>this.onSms(result))
  }

  removeFromList(){
    var tot:number = this.proffesionals.length
    this.profToDel = [];
    for (var i = 0; i < tot; i++) {
      if($('#'+this.proffesionals[i].PkProffesional).prop('checked')) this.profToDel.push(this.proffesionals[i].PkProffesional);
    }
    if(confirm('Are you sure to delete this profesionals from the list?')){      
      this._api.removeFromList(this.selLista, this.profToDel)
        .subscribe(result=>this.onProffesionals(result))
      this._api.getLists(this.user.PkUser)
        .subscribe(result=>this.onLists(result))       
    }
  }

  public onMailing(data:object){
    alert('Mail sent !');
  }

  public onSms(data:object){
    alert('Text sent !');
  }

  public onChange(evt){}
  public onEditorChange(evt){}
  public onReady(evt){}
  public onFocus(evt){}
  public onBlur(evt){}
  public onContentDom(evt){}
  public onPaste(evt){}
  public onDrop(evt){}
}
