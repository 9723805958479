<div class="container">
    <div class="login"> 
      <form class="form-signin">
        <img class="mb-4" src="assets/img/logos/logo.png" alt="Transforma" width="auto">
        <p class="text-center text-warning">Already have an account?</p>
        <label for="inputEmail" class="block text-warning">Email</label>
        <input type="email" [(ngModel)]="email"  id="inputEmail" class="form-control" name="email" required autofocus >

        <br>
        <div *ngIf="!recovery">
            <label for="inputPassword" class="control-label text-warning">Password</label>
            <input type="password" [(ngModel)]="password" id="inputPassword" class="form-control" name="password" required >
        </div>
        <p class="text-center text-warning">{{askforpassword}}</p>
        <div class="d-flex justify-content-center mb-4">
            <p><small class="text-center text-danger">{{loginerror}}&nbsp;&nbsp;</small></p>
            <p><small *ngIf="!recovery"><a href="javascript:void(0)" class="text-warning" (click)="askForPass()">Forgot password?</a></small></p>
        </div> 
        <p>&nbsp;</p>
        <button class="btn btn-lg btn-secondary btn-block" type="submit" (click)="loginUser()">{{label}}</button>
      </form>
        <p>&nbsp;</p>
      <div *ngIf="!isMobile">      
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
</div>