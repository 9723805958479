<div class="wrapper wrapper_lg air full bg-white">
	<div class="d-flex row justify-content-end" *ngIf="isMobile">
		<button class="btn btn-outline-secondary mt-4 mb-4 mr-4" (click)="goBack()">< Back</button>
	</div>
	<div class="d-flex row justify-content-end mr-4" *ngIf="!isMobile">
		<button type="button" class="close" (click)="closeWindow()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
	<div class="d-flex row justify-content-center">
		<canvas id="myCanvas" width="500" height="500"></canvas>
		<!-- <img src="{{image}}"> -->
	</div>
	<div class="d-flex row justify-content-center mt-4" *ngIf="isMe">
		<button (click)="rotate()" class="btn-sm btn-primary">
			<i class="fas fa-sync fa-2x"></i>
		</button>&nbsp;&nbsp;
		<button (click)="saveImg()" class="btn-sm btn-primary">
			<i class="fas fa-save fa-2x"></i>
		</button>
	</div>
</div>