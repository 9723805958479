  <app-navigation></app-navigation> 
 
   <app-search-box mode="3"></app-search-box>
   <
   <div class="container bg-white">
    <table class="table table-bordered table-hover">
      <thead class="thead-light" >
        <tr>
          <th scope="col">Name</th>
          <th scope="col">City</th>        
          <th scope="col">Specialty</th>
          <th scope="col">Hourly</th>
          <th scope="col">Exp.</th>
          <th scope="col"><input type="checkbox" name="selectall" id="selectall" (click)="checkAll()"></th>
        </tr>
       
      </thead>
      <tbody *ngIf="proffesionals">
        <tr *ngFor="let item of proffesionals">
          <th scope="row"><a href="javascript:void" routerLink="/prodetail/{{item.PkProffesional}}">{{item.FirstName}} {{item.LastName}}</a></th>
          <td>{{item.City}}</td>
          <td>{{item.SpecialtyName}}</td>
          <td>${{item.HourlyRate}}</td>
          <td>{{item.Experience}}</td>
          <td><input type="checkbox" [name]="item.PkProffesional" [id]="item.PkProffesional"></td>
        </tr>
         <tr *ngIf="proffesionals && proffesionals.length<1"><td colspan="6">No professionals found</td></tr>
      </tbody>
    </table>  
  <div class="d-flex w-100 justify-content-end" *ngIf="proffesionals && proffesionals.length>0">
    <button class="btn btn-outline-secondary" (click)="deleteProf()">Delete selected</button>
  </div>
  <nav aria-label="...">
    <ul class="pagination pagination-md">
      <li *ngFor="let item of pages"  [class]="(item==currentPage)?'page-item disabled':'page-item'"><a href="javascript:void(0)" class="page-link" (click)="loadPage(item)">{{item}}</a></li>
    </ul>
  </nav>
  <br>
</div>
<app-footer></app-footer>

<ng-template #content let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">New list</h5>
        <button type="button" class="close" aria-label="Close" (click)="auxModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <label for="listname">List name</label>&nbsp;&nbsp;
        <input type="text" name="listname" [(ngModel)]="listname">
        <p><span class="text-center text-danger">{{listerror}}</span></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="auxModal.dismiss('Cross click')">Close</button>
        <button type="button" class="btn btn-primary" (click)="saveList();">Save</button>
      </div>
</ng-template>